import ProjectSaga from './saga';
import { RequestStatus } from 'constants/API';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { ProjectState } from './types';
export const initialState: ProjectState = {
  getProjectListStatus: RequestStatus.IDLE,
  getProjectStatus: RequestStatus.IDLE,
  createProjectStatus: RequestStatus.IDLE,
  updateProjectStatus: RequestStatus.IDLE,
  deleteProjectStatus: RequestStatus.IDLE,
  updateProjectImageStatus: RequestStatus.IDLE,
  getProjectSummaryStatus: RequestStatus.IDLE,

  project_meta_data: null,
  projectList: null,
  projectInfo: null,
  projectSummary: null,
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    getProjectListRequest: (state, action: PayloadAction<any>) => {
      state.getProjectListStatus = RequestStatus.REQUESTING;
    },
    getProjectListSuccess: (state, action: PayloadAction<any>) => {
      state.getProjectListStatus = RequestStatus.SUCCESS;
      state.projectList = action.payload;
    },
    getProjectListMetaDataSuccess: (state, action: PayloadAction<any>) => {
      state.getProjectListStatus = RequestStatus.SUCCESS;
      state.project_meta_data = action.payload;
    },
    getProjectListFail: state => {
      state.getProjectListStatus = RequestStatus.ERROR;
    },
    getProjectRequest: (state, action: PayloadAction<any>) => {
      state.getProjectStatus = RequestStatus.REQUESTING;
    },
    getProjectSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getProjectStatus = RequestStatus.SUCCESS;
        state.projectInfo = action.payload;
      }
    },
    getProjectFail: (state, action: PayloadAction<any>) => {
      state.getProjectStatus = RequestStatus.ERROR;
    },
    createProjectRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createProjectStatus = RequestStatus.REQUESTING;
    },
    createProjectSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createProjectStatus = RequestStatus.SUCCESS;
    },
    createProjectFail: (state, action: PayloadAction<any>) => {
      state.createProjectStatus = RequestStatus.ERROR;
    },
    updateProjectRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateProjectStatus = RequestStatus.REQUESTING;
    },
    updateProjectSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.updateProjectStatus = RequestStatus.SUCCESS;
        state.projectInfo = action.payload;
      }
    },
    updateProjectFail: (state, action: PayloadAction<any>) => {
      state.updateProjectStatus = RequestStatus.ERROR;
    },
    updateProjectImageRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateProjectImageStatus = RequestStatus.REQUESTING;
    },
    updateProjectImageSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.updateProjectImageStatus = RequestStatus.SUCCESS;
      }
    },
    updateProjectImageFail: (state, action: PayloadAction<any>) => {
      state.updateProjectImageStatus = RequestStatus.ERROR;
    },
    deleteProjectRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteProjectStatus = RequestStatus.REQUESTING;
    },
    deleteProjectSuccess: (state, action: PayloadAction<any>) => {
      state.deleteProjectStatus = RequestStatus.SUCCESS;
    },
    deleteProjectFail: (state, action: PayloadAction<any>) => {
      state.deleteProjectStatus = RequestStatus.ERROR;
    },

    getProjectSummaryRequest: (state, action: PayloadAction<any>) => {
      state.getProjectSummaryStatus = RequestStatus.REQUESTING;
    },
    getProjectSummarySuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getProjectSummaryStatus = RequestStatus.SUCCESS;
        state.projectSummary = action.payload;
      }
    },
    getProjectSummaryFail: (state, action: PayloadAction<any>) => {
      state.getProjectSummaryStatus = RequestStatus.ERROR;
    },

    resetProjectRequestStatus: state => {
      state.getProjectListStatus = RequestStatus.IDLE;
      state.getProjectStatus = RequestStatus.IDLE;
      state.getProjectSummaryStatus = RequestStatus.IDLE;
      state.createProjectStatus = RequestStatus.IDLE;
      state.updateProjectStatus = RequestStatus.IDLE;
      state.deleteProjectStatus = RequestStatus.IDLE;
    },
  },
});

export default slice.actions;

export const useProjectsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: ProjectSaga });
  return { actions: slice.actions };
};
