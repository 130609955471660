import React, { useRef } from 'react';

// UI
import {
  Box,
  Stack,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  Autocomplete,
  IconButton,
} from '@mui/material';

import { Search as SearchIcon } from '@mui/icons-material';

// Redux & Sagas
import { useSelector } from 'react-redux';

// Library
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Path from 'config/clientPath';
import queryString from 'query-string';
import { ICommonDataSchema } from 'types/common';
import styled from 'styled-components/macro';
import _ from 'lodash';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { selectTimesheet } from 'app/pages/TimesheetPage/slice/selectors';
import { RequestStatus } from 'constants/API';

export function FilterBar() {
  const { handleSubmit, control, watch, setValue, getValues } = useForm();

  const { allProjects, getAllProjectsStatus } = useSelector(selectTimesheet);
  const defaultProject = { id: -1, name: 'All' };
  const [selectedProject, setSelectedProject] =
    React.useState<ICommonDataSchema | null>(defaultProject);
  const isFirstRender = useRef(true);

  const searchForm = React.useRef<HTMLFormElement>();
  const history = useHistory();

  const handSearch = formData => {
    const params = queryString.parse(window.location.search);
    let newParams = {
      ...formData,
      project: selectedProject?.id || '',
      month: formData?.month.format('YYYY-MM'),
      member: params?.member || '',
    };
    if (params?.page) {
      newParams = { ...newParams, page: 1 };
    }
    // remove query param when has not value or value is -1 (all value)
    const qs = queryString.stringify(_.omitBy(newParams, v => !v || v === -1));
    history.push(`${Path.TIMESHEET_APPROVAL_REVIEW}?${qs}`);
  };

  React.useEffect(() => {
    const params = queryString.parse(window.location.search);
    setValue('month', params?.month ? moment(params?.month) : moment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (getAllProjectsStatus === RequestStatus.SUCCESS) {
      const params = queryString.parse(window.location.search);
      let newProject = defaultProject.name;
      if (params?.project) {
        const project = allProjects?.find(
          v => v.id === _.toNumber(params?.project),
        );
        if (project) {
          setSelectedProject(project);
          newProject = project.name;
        }
      }
      setValue('project', newProject);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllProjectsStatus]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'project' || name === 'member' || name === 'month') {
        if (isFirstRender.current) {
          isFirstRender.current = false;
        } else {
          requestSubmit();
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const requestSubmit = () => {
    setTimeout(() => searchForm.current?.requestSubmit(), 10);
  };

  const resetProjectAndMember = () => {
    setSelectedProject(defaultProject);
  };

  const changeMonth = diff_month => {
    setValue(
      'month',
      (getValues('month') || moment()).clone().add(diff_month, 'month'),
    );
    resetProjectAndMember();
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      component="form"
      method="GET"
      ref={searchForm}
      onSubmit={handleSubmit(handSearch)}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <InputLabel>Month: </InputLabel>
        <IconButton>
          <ChevronLeft
            onClick={() => {
              changeMonth(-1);
            }}
          />
        </IconButton>
        <FormControl margin="dense" sx={{ width: 115 }}>
          <Controller
            control={control}
            name="month"
            render={({ field: { onChange, value } }) => {
              return (
                <DatePicker
                  views={['month', 'year']}
                  value={value}
                  onChange={e => {
                    resetProjectAndMember();
                    onChange(e);
                  }}
                  inputFormat="YYYY/MM"
                  renderInput={params => (
                    <TextField {...params} fullWidth hiddenLabel size="small" />
                  )}
                />
              );
            }}
          />
        </FormControl>
        <IconButton>
          <ChevronRight
            onClick={() => {
              changeMonth(1);
            }}
          />
        </IconButton>

        <InputLabel>Project: </InputLabel>
        <FormControlCustom>
          <Controller
            control={control}
            name="project"
            render={({ field }) => {
              const { onChange } = field;
              return (
                <Autocomplete
                  value={selectedProject}
                  isOptionEqualToValue={(option, value) => {
                    return value?.id && value?.id === option?.id ? true : false;
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={`prj_${option.id}`}>
                      {option?.name}
                    </Box>
                  )}
                  options={[{ ...defaultProject }, ...allProjects]}
                  getOptionLabel={it => it?.name || ''}
                  onChange={(e, newValue) => {
                    setSelectedProject(newValue);
                    onChange(newValue?.name);
                  }}
                  renderInput={params => {
                    return (
                      <TextField {...params} size="small" variant="outlined" />
                    );
                  }}
                />
              );
            }}
          />
        </FormControlCustom>
      </Stack>

      <Stack direction="row" spacing={2} alignItems="center">
        <TextField
          type="search"
          placeholder="Search keyword"
          aria-label="Search"
          size="small"
          sx={{
            background: '#fff',
          }}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              requestSubmit();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          // {...register<keyof IMemberSchema>('full_name')}
        />
      </Stack>
    </Box>
  );
}

export default FilterBar;

const FormControlCustom = styled(FormControl)`
  width: 150px;
  & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon,
  .MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      padding-right: 50px !important;
    }
  }
  &.member_form {
    width: 190px;
    & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon,
    .MuiAutocomplete-root {
      .MuiOutlinedInput-root {
        padding-right: 50px !important;
      }
    }
  }
`;
