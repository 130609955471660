import { ReactComponent as CustomerIcon } from 'assets/icons/sidebar/customer.svg';
import { ReactComponent as ProjectIcon } from 'assets/icons/sidebar/project.svg';
import { ReactComponent as MemberIcon } from 'assets/icons/sidebar/member.svg';
import { ReactComponent as TeamIcon } from 'assets/icons/sidebar/team.svg';
import { ReactComponent as TimesheetIcon } from 'assets/icons/sidebar/timesheet.svg';
import { ReactComponent as AssignmentIcon } from 'assets/icons/sidebar/assignment.svg';
import { ReactComponent as ProposalIcon } from 'assets/icons/sidebar/proposal.svg';

import Path from 'config/clientPath';
import Roles from 'constants/roles';
import { ROUTES_INFO } from './common';

const MENU_LIST = [
  {
    path: Path.TEAM_PROPOSAL,
    menuName: 'Team Proposal',
    icon: <ProposalIcon />,
    role: Roles.TEAM_PROPOSAL_ALL,
    useRegex: true,
  },
  {
    path: Path.CUSTOMERS,
    menuName: 'Customers',
    icon: <CustomerIcon />,
    role: Roles.CUSTOMER_ALL,
    useRegex: true,
  },
  {
    path: Path.PROJECTS,
    menuName: 'Projects',
    icon: <ProjectIcon />,
    role: Roles.PROJECT_ALL,
    useRegex: true,
  },
  {
    path: Path.MEMBERS,
    menuName: 'Members',
    icon: <MemberIcon />,
    role: Roles.MEMBER_ALL,
    useRegex: true,
  },
  {
    path: Path.TEAM,
    menuName: 'Teams',
    icon: <TeamIcon />,
    role: Roles.TEAM_ALL,
    useRegex: true,
  },
  {
    path: Path.TIMESHEET,
    menuName: 'Timesheets',
    icon: <TimesheetIcon />,
    role: Roles.WORKREPORT_ALL,
    useRegex: true,
    items: [
      {
        path: Path.TIMESHEET_OVERVIEW,
        menuName: 'Overview',
        role: Roles.WORKREPORT_APPROVE,
        useRegex: true,
      },
      {
        path: Path.TIMESHEET,
        menuName: 'Task list',
        role: Roles.WORKREPORT_ALL,
        useRegex: true,
      },
      {
        path: Path.TIMESHEET_REPORT,
        menuName: 'Report',
        role: Roles.WORKREPORT_ALL,
        useRegex: true,
      },
      {
        path: Path.TIMESHEET_APPROVAL,
        menuName: 'Approval',
        role: Roles.WORKREPORT_APPROVE,
        useRegex: true,
      },
    ],
  },
  {
    path: Path.ASSIGNMENT_SUMMARY,
    menuName: 'Assignment',
    icon: <AssignmentIcon />,
    role: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].role,
    roles: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].roles,
    useRegex: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].useRegex,
    useHasOneRole: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].useHasOneRole,
    items: [
      {
        path: Path.ASSIGNMENT_SUMMARY,
        menuName: 'Summary',
        role: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].role,
        roles: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].roles,
        useRegex: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].useRegex,
        useHasOneRole: ROUTES_INFO[Path.ASSIGNMENT_SUMMARY].useHasOneRole,
      },
      {
        path: Path.COMMIT_EFFORT,
        menuName: 'Commit Effort',
        role: ROUTES_INFO[Path.COMMIT_EFFORT].role,
        roles: ROUTES_INFO[Path.COMMIT_EFFORT].roles,
        useRegex: ROUTES_INFO[Path.COMMIT_EFFORT].useRegex,
        useHasOneRole: ROUTES_INFO[Path.COMMIT_EFFORT].useHasOneRole,
      },
    ],
  },
  {
    path: Path.ASSIGNABLE,
    menuName: 'Assignable',
    icon: <AssignmentIcon />,
    role: ROUTES_INFO[Path.ASSIGNABLE].role,
    roles: ROUTES_INFO[Path.ASSIGNABLE].roles,
    useRegex: ROUTES_INFO[Path.ASSIGNABLE].useRegex,
    useHasOneRole: ROUTES_INFO[Path.ASSIGNABLE].useHasOneRole,
  },
  {
    path: Path.CUSTOMER_TIMESHEET_REPORT,
    menuName: 'Timesheet Report',
    icon: <TimesheetIcon />,
    role: ROUTES_INFO[Path.CUSTOMER_TIMESHEET_REPORT].role,
    roles: ROUTES_INFO[Path.CUSTOMER_TIMESHEET_REPORT].roles,
    useRegex: ROUTES_INFO[Path.CUSTOMER_TIMESHEET_REPORT].useRegex,
    useHasOneRole: ROUTES_INFO[Path.CUSTOMER_TIMESHEET_REPORT].useHasOneRole,
  },
];

export default MENU_LIST;
