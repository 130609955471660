import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { RangeInput } from '@mui/lab/DateRangePicker/RangeTypes';
import {
  Autocomplete,
  Box,
  Button,
  FormLabel,
  Grid,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import { PageTitle } from 'app/components/PageTitle';
import TimeRangeSelectFilter from 'app/components/TimeRangeSelectFilter';
import { useCustomerSlice } from 'app/pages/CustomerPage/slice';
import { selectCustomer } from 'app/pages/CustomerPage/slice/selectors';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import { IMemberProjectSchema } from 'app/pages/MemberPage/slice/types';
import { selectTeamProposal } from 'app/pages/TeamProposal/slice/selectors';
import { ITeamProposalSchema } from 'app/pages/TeamProposal/slice/types';
import { RequestStatus } from 'constants/API';
import _ from 'lodash';
import * as React from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { ICommonDataSchema } from 'types/common';

export interface IProposalFormProps {
  teamProposalForm: UseFormReturn;
  handleSaveData: SubmitHandler<FieldValues>;
  handleResetData: SubmitHandler<FieldValues>;
  isCreateProposal: boolean;
  formData: IMemberProjectSchema;
}

export default function ProposalForm(props: IProposalFormProps) {
  const dispatch = useDispatch();

  const {
    actions: { getProjectRoleListRequest },
  } = useMemberSlice();
  const { getCustomerListRequest } = useCustomerSlice().actions;
  const { updateTeamProposalStatus, createTeamProposalStatus } =
    useSelector(selectTeamProposal);
  const { listCustomer } = useSelector(selectCustomer);

  const commonMapping = (arr: any): ICommonDataSchema[] => {
    return arr.map(it => _.pick(it, ['id', 'name']));
  };
  const defaultCustomer = { id: -1, name: '' };
  const [selectedCustomer, setSelectedCustomer] =
    React.useState<ICommonDataSchema | null>(defaultCustomer);

  const [dateFilter, setDateFilter] = React.useState<RangeInput<any>>([
    null,
    null,
  ]);

  const {
    register,
    getValues,
    setValue,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = props.teamProposalForm;

  const setDateToForm = val => {
    setDateFilter(val);
    setValue('start_date', val[0]);
    setValue('end_date', val[1]);
    setValue('daterange', val);
  };
  const { isCreateProposal, formData } = props;
  React.useEffect(() => {
    dispatch(getProjectRoleListRequest({}));
    dispatch(getCustomerListRequest({}));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (isCreateProposal) {
      reset({
        id: null,
        title: null,
        description: null,
        start_date: null,
        end_date: null,
        customer: null,
        project: null,
      });
    } else {
      reset(formData);
      setDateFilter([formData.start_date, formData.end_date]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateProposal, formData]);
  React.useEffect(() => {
    const customer = getValues('customer');

    setSelectedCustomer(
      customer
        ? {
            id: customer,
            name: _.get(
              _.find(listCustomer?.data, {
                id: _.toInteger(customer),
              }),
              'name',
            ),
          }
        : defaultCustomer,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues('customer')]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={10}
      >
        <PageTitle>{isCreateProposal ? 'Create new' : 'Edit'}</PageTitle>
        <Stack
          alignSelf="center"
          pr={1}
          direction="row"
          justifyContent="flex-end"
          spacing={2}
        >
          <LoadingButton
            type="submit"
            variant="contained"
            loadingPosition="start"
            startIcon={<SaveIcon />}
            onClick={handleSubmit(props.handleSaveData)}
            loading={
              updateTeamProposalStatus === RequestStatus.REQUESTING ||
              createTeamProposalStatus === RequestStatus.REQUESTING
            }
          >
            Save
          </LoadingButton>
          <Button onClick={props.handleResetData} variant="outlined">
            Cancel
          </Button>
        </Stack>
      </Box>
      <FormContainer>
        <Grid container spacing={3} style={{ padding: '20px' }}>
          <Grid item xs={12} lg={12}>
            <Stack>
              <Grid container spacing={2} pb={4} pt={2}>
                <Grid item xs={6}>
                  <FormLabel required>Customers</FormLabel>
                  <Controller
                    control={control}
                    name="customer"
                    rules={{ required: true }}
                    render={({ field }) => {
                      const { onChange } = field;
                      return (
                        <Autocomplete
                          value={selectedCustomer}
                          isOptionEqualToValue={(option, value) => {
                            return value?.id && value?.id === option?.id
                              ? true
                              : false;
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              {...props}
                              key={`customer_${option.id}`}
                            >
                              {option?.name}
                            </Box>
                          )}
                          options={[
                            { ...defaultCustomer },
                            ...commonMapping(listCustomer?.data ?? []),
                          ]}
                          getOptionLabel={it => it?.name || ''}
                          onChange={(e, newValue) => {
                            setSelectedCustomer(newValue);
                            onChange(newValue?.id);
                          }}
                          renderInput={params => {
                            return (
                              <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormLabel>Project</FormLabel>
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    error={errors.project ? true : false}
                    {...register<keyof ITeamProposalSchema>('project', {
                      maxLength: 60,
                    })}
                    helperText={errors.project?.message}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormLabel required>Title</FormLabel>
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    size="small"
                    error={errors.title ? true : false}
                    {...register<keyof ITeamProposalSchema>('title', {
                      required: 'This is required.',
                      maxLength: 60,
                    })}
                    helperText={errors.title?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel>Description</FormLabel>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    size="small"
                    fullWidth
                    multiline
                    rows={5}
                    error={errors.description ? true : false}
                    {...register<keyof ITeamProposalSchema>('description')}
                    helperText={errors.description?.message}
                  />
                </Grid>
                <Grid item xs={6} className="form-date-input">
                  <FormLabel required>Period</FormLabel>
                  <Controller
                    control={control}
                    name={'daterange'}
                    render={() => {
                      return (
                        <TimeRangeSelectFilter
                          value={dateFilter}
                          setValue={newValue => setDateToForm(newValue)}
                        />
                      );
                    }}
                  ></Controller>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </FormContainer>
    </>
  );
}

const FormContainer = styled(Paper)`
  .form-date-input {
    .MuiFormControl-marginDense:first-child {
      .MuiButtonBase-root svg {
        display: none;
      }
      .MuiOutlinedInput-notchedOutline {
        border-right: none;
        border-radius: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    .MuiFormControl-marginDense:nth-child(2) {
      margin-left: -1px;
      .MuiOutlinedInput-notchedOutline {
        border-left: none;
        border-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .MuiFormLabel-asterisk {
    color: #ff4d4f;
  }
  .MuiAutocomplete-root {
    margin: 8px 0 4px 0;
  }
`;
