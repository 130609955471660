import React from 'react';

// UI
import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from '@mui/material';

// Redux & Sagas
import { UseFormReturn } from 'react-hook-form';

// Library
import _ from 'lodash';

interface IProjectTableProps {
  itemIndex: number;
  memberForm: UseFormReturn;
}

export default function ProjectTable(props: IProjectTableProps) {
  const {
    register,
    formState: { errors },
  } = props.memberForm;

  const fieldName = `project_histories[${props.itemIndex}]`;

  return (
    <TableContainer component={Paper} variant="outlined" square>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell component="th" width={140}>
              プロジェクト名
            </TableCell>
            <TableCell width={550}>
              <TextField
                size="small"
                variant="outlined"
                hiddenLabel
                fullWidth
                error={
                  _.get(errors, `${fieldName}.project_name`) ? true : false
                }
                {...register(`${fieldName}.project_name`, {
                  required: 'This is required.',
                  maxLength: 200,
                })}
                helperText={_.get(errors, `${fieldName}.project_name`)?.message}
              />
            </TableCell>
            <TableCell component="th" width={100}>
              開発期間
            </TableCell>
            <TableCell>
              <TextField
                size="small"
                variant="outlined"
                placeholder="2020/10 - 2022/09"
                hiddenLabel
                fullWidth
                error={_.get(errors, `${fieldName}.period`) ? true : false}
                {...register(`${fieldName}.period`, {
                  required: 'This is required.',
                  maxLength: 200,
                })}
                helperText={_.get(errors, `${fieldName}.period`)?.message}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" width={140}>
              会社名
            </TableCell>
            <TableCell width={550}>
              <TextField
                size="small"
                variant="outlined"
                hiddenLabel
                fullWidth
                error={
                  _.get(errors, `${fieldName}.company_name`) ? true : false
                }
                {...register(`${fieldName}.company_name`, {
                  required: 'This is required.',
                  maxLength: 200,
                })}
                helperText={_.get(errors, `${fieldName}.company_name`)?.message}
              />
            </TableCell>
            <TableCell component="th">URL</TableCell>
            <TableCell>
              <TextField
                size="small"
                variant="outlined"
                hiddenLabel
                fullWidth
                error={_.get(errors, `${fieldName}.url`) ? true : false}
                {...register(`${fieldName}.url`, {
                  maxLength: 200,
                })}
                helperText={_.get(errors, `${fieldName}.url`)?.message}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">担当フェーズ</TableCell>
            <TableCell>
              <TextField
                size="small"
                variant="outlined"
                placeholder="design, develop, test, deploy, maintaince"
                hiddenLabel
                fullWidth
                error={_.get(errors, `${fieldName}.scope`) ? true : false}
                {...register(`${fieldName}.scope`, {
                  maxLength: 200,
                })}
                helperText={_.get(errors, `${fieldName}.scope`)?.message}
              />
            </TableCell>
            <TableCell component="th">参加メンバー </TableCell>
            <TableCell>
              <TextField
                size="small"
                variant="outlined"
                hiddenLabel
                fullWidth
                error={_.get(errors, `${fieldName}.team_size`) ? true : false}
                {...register(`${fieldName}.team_size`, {
                  maxLength: 200,
                })}
                helperText={_.get(errors, `${fieldName}.team_size`)?.message}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">対応可能言語</TableCell>
            <TableCell colSpan={3}>
              <TextField
                size="small"
                variant="outlined"
                hiddenLabel
                fullWidth
                multiline
                rows={3}
                error={
                  _.get(errors, `${fieldName}.language_info`) ? true : false
                }
                {...register(`${fieldName}.language_info`, {
                  maxLength: 1000,
                })}
                helperText={
                  _.get(errors, `${fieldName}.language_info`)?.message
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th">概要</TableCell>
            <TableCell colSpan={3}>
              <TextField
                size="small"
                variant="outlined"
                hiddenLabel
                fullWidth
                multiline
                rows={4}
                error={_.get(errors, `${fieldName}.description`) ? true : false}
                {...register(`${fieldName}.description`, {
                  maxLength: 2000,
                })}
                helperText={_.get(errors, `${fieldName}.description`)?.message}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
