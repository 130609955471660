import React, { useEffect, useState } from 'react';
// UI
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material';

import { REPORT_TYPE, ASSIGNMENT_TYPE } from 'constants/common';
import moment from 'moment';
import _ from 'lodash';

import ProfileInfo from 'app/components/ProfileInfo';
import WorkReportChart from 'app/components/Charts/WorkReport';
import { TagComponent } from 'app/components/TagComponent';

// Redux & Sagas
import { useSelector, useDispatch } from 'react-redux';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import { useTagSlice } from 'app/pages/TagPage/slice';
import { Box } from '@mui/system';
import AssignInfo from '../AssignInfo';
import { ProjectLink } from '../ProjectLink';

interface IMemberReportProps {
  memberId: number | string;
  type: string;
  month: moment.Moment | null;
}

export default function MemberReport(props: IMemberReportProps) {
  const { memberId, type, month } = props;
  const isViewer = type === 'viewer';
  const [projectsByMonth, setProjectsByMonth] = useState<any>([]);

  const [roles, setRoles] = useState({});
  const dispatch = useDispatch();

  const {
    actions: {
      getMemberWorkReportRequest,
      getProjectRoleListRequest,
      getMemberEffortRequest,
      getSkillListRequest,
    },
  } = useMemberSlice();

  const {
    actions: { getTagRequest },
  } = useTagSlice();

  const {
    project_role_metadata,
    workHoursInfo,
    memberTasks,
    memberInfo,
    getProjectRoleListStatus,
  } = useSelector(selectMember);

  useEffect(() => {
    dispatch(getProjectRoleListRequest({}));
    dispatch(getTagRequest({}));
    dispatch(getSkillListRequest({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadWorkReport = () => {
    dispatch(
      getMemberWorkReportRequest({
        date_start: month?.clone().startOf('month').format('YYYY-MM-DD'),
        date_end: month?.clone().endOf('month').format('YYYY-MM-DD'),
        report_type: REPORT_TYPE.DAY,
        members: [memberId],
      }),
    );
  };

  useEffect(() => {
    if (memberId && month) {
      reloadWorkReport();
      dispatch(
        getMemberEffortRequest({
          member: memberId,
          month: month?.clone().format('YYYY-MM'),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId, month]);

  useEffect(() => {
    let _roles = {};
    _.forEach(project_role_metadata, v => {
      _roles = { ..._roles, [v.id]: v.name };
    });
    setRoles(_roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectRoleListStatus]);

  useEffect(() => {
    if (memberInfo?.projects.length && month) {
      const startOfMonth = month?.clone().startOf('month');
      const endOfMonth = month?.clone().endOf('month');
      let tempProjects: any[] = [];
      _.map(memberInfo?.projects, v => {
        if (!v.end_date && moment(v.start_date).isSameOrBefore(endOfMonth)) {
          tempProjects = [...tempProjects, { ...v }];
        } else if (v.end_date) {
          if (
            (moment(v.end_date).isSameOrAfter(startOfMonth) &&
              moment(v.end_date).isSameOrBefore(endOfMonth)) ||
            (moment(v.end_date).isAfter(endOfMonth) &&
              moment(v.start_date).isSameOrBefore(endOfMonth))
          )
            tempProjects = [...tempProjects, { ...v }];
        }
      });
      setProjectsByMonth(tempProjects);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberInfo, month]);

  return (
    <>
      <Stack direction="column">
        <Card>
          <CardContent>
            <ProfileInfo />
            <AssignInfo totalProjects={projectsByMonth?.length} />
          </CardContent>
        </Card>
        <Grid container spacing={2} py={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" component="h6">
                  Work Report
                </Typography>
                <Box mt={2}>
                  {workHoursInfo && !_.isEmpty(workHoursInfo['data']) ? (
                    <WorkReportChart isViewer={isViewer} data={workHoursInfo} />
                  ) : (
                    'No data'
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                  Assignment
                </Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width={60}>No</TableCell>
                        <TableCell width={120}>Project</TableCell>
                        <TableCell width={120}>Type</TableCell>
                        <TableCell width={120}>Role</TableCell>
                        <TableCell width={90}>Hours</TableCell>
                        <TableCell width={120}>Start date</TableCell>
                        <TableCell width={120}>End date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {projectsByMonth?.map((v, k) => (
                        <TableRow key={k}>
                          <TableCell>{k + 1}</TableCell>
                          <TableCell>
                            <ProjectLink
                              project_id={v.project}
                              project_name={v.project_name}
                            ></ProjectLink>
                          </TableCell>
                          <TableCell>{ASSIGNMENT_TYPE[v.type]}</TableCell>
                          <TableCell>{roles[v.role]}</TableCell>
                          <TableCell>{v.effort_per_month}h</TableCell>
                          <TableCell>{v.start_date}</TableCell>
                          <TableCell>{v.end_date}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {isViewer && (
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h6" component="h2">
                Task Logs
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 60 }} width={60}>
                        No
                      </TableCell>
                      <TableCell sx={{ minWidth: 70 }} width={120}>
                        Date
                      </TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Project</TableCell>
                      <TableCell sx={{ minWidth: 110 }}>
                        Task description
                      </TableCell>
                      <TableCell sx={{ minWidth: 70 }} width={120}>
                        Tag
                      </TableCell>
                      <TableCell sx={{ minWidth: 70 }} width={120}>
                        Duration
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {memberTasks?.map((v, k) => (
                      <TableRow key={k}>
                        <TableCell>{k + 1}</TableCell>
                        <TableCell>{v.date}</TableCell>
                        <TableCell>{v.project.name}</TableCell>
                        <TableCell>{v.description}</TableCell>
                        <TableCell>
                          <Stack spacing={1}>
                            {v.tags.map((v, k) => (
                              <TagComponent data={v} key={k} />
                            ))}
                          </Stack>
                        </TableCell>
                        <TableCell>{v.duration}h</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        )}
      </Stack>
    </>
  );
}
