import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Container } from 'app/components/Container';
import { Loader } from 'app/components/Loader';
import { Content, Wrapper } from 'app/components/Wrapper';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
// Library
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
// UI
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { checkIsRequesting } from 'utils/helper';
import { convertDate } from 'utils/moment';
import { useTeamSlice } from '../TeamPage/slice';
import { selectTeam } from '../TeamPage/slice/selectors';
import { useTeamProposalSlice } from '../TeamProposal/slice';
import { selectTeamProposal } from '../TeamProposal/slice/selectors';
import MemberDialogForm from './components/MemberDialogForm';
import MemberListTable from './components/MemberListTable';
import ProposalForm from './components/ProposalForm';

interface RouteParams {
  id: string;
}

export const STATUS_LIST = {
  '1': 'Active',
  '2': 'Deactive',
};

export function TeamProposalEditPage() {
  const dispatch = useDispatch();
  const [isCreateProposal, setIsCreateProposal] = useState<boolean>(true);
  const [formData, setFormData] = useState<any>({});
  const [members, setMembers] = useState<any>([]);
  const params = useParams<RouteParams>();
  const history = useHistory();
  const {
    actions: {
      getTeamProposalRequest,
      createTeamProposalRequest,
      resetTeamProposalRequestStatus,
      updateTeamProposalRequest,
    },
  } = useTeamProposalSlice();

  const { setBreadcumbs, setErrorMessages, setSuccessMessages } =
    useGlobalSlice().actions;

  const {
    teamProposal,
    getTeamProposalStatus,
    updateTeamProposalStatus,
    createTeamProposalStatus,
  } = useSelector(selectTeamProposal);

  const { getTeamListRequest } = useTeamSlice().actions;
  const { listTeam, getTeamListStatus } = useSelector(selectTeam);
  const { member_meta_data, project_role_metadata, getMemberListStatus } =
    useSelector(selectMember);
  const { getMemberListRequest } = useMemberSlice().actions;

  const teamProposalForm = useForm();

  const isLoading = checkIsRequesting([
    getTeamProposalStatus,
    updateTeamProposalStatus,
    getTeamListStatus,
  ]);

  useEffect(() => {
    if (params.id) {
      setIsCreateProposal(false);

      dispatch(
        setBreadcumbs([
          { title: 'Team Proposal', path: Path.TEAM_PROPOSAL },
          { title: 'Edit' },
        ]),
      );
      dispatch(getTeamProposalRequest(params.id));
    } else {
      setIsCreateProposal(true);
      setFormData({});
      teamProposalForm.reset();

      dispatch(
        setBreadcumbs([
          { title: 'Team Proposal', path: Path.TEAM_PROPOSAL },
          { title: 'Create new' },
        ]),
      );
    }
    dispatch(getMemberListRequest({ paging: false }));
    dispatch(
      getTeamListRequest({
        page: 1,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    teamProposalForm.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  useEffect(() => {
    if (updateTeamProposalStatus === RequestStatus.SUCCESS) {
      teamProposalForm.reset();
      dispatch(setSuccessMessages(['Proposal update successful!']));
      history.push(`${Path.TEAM_PROPOSAL}`);
    }
    if (createTeamProposalStatus === RequestStatus.SUCCESS) {
      teamProposalForm.reset();
      dispatch(setSuccessMessages(['Proposal created successful!']));
      history.push(`${Path.TEAM_PROPOSAL}`);
    }
    dispatch(resetTeamProposalRequestStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTeamProposalStatus, createTeamProposalStatus]);

  useEffect(
    () => {
      return () => {
        setMemberList([]);
        dispatch(resetTeamProposalRequestStatus());
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  useEffect(() => {
    if (getTeamProposalStatus === RequestStatus.SUCCESS) {
      let cloneTeamProposal = _.cloneDeep(teamProposal);

      // teamProposalForm.reset(_.omit(cloneTeamProposal, ['members']));
      setFormData(_.omit(cloneTeamProposal, ['members']));
      setMemberList(teamProposal?.members ?? []);
    }
    dispatch(resetTeamProposalRequestStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeamProposalStatus, getTeamListStatus, getMemberListStatus]);

  useEffect(() => {
    if (
      getTeamListStatus === RequestStatus.SUCCESS &&
      getMemberListStatus === RequestStatus.SUCCESS
    ) {
      const members = member_meta_data;
      const teams = listTeam?.data;
      const membersWithTeam = members?.map(it => {
        const id = it.id;
        return {
          ...it,
          teams: teams
            ?.filter(item => _.find(item.members, { id: _.toInteger(id) }))
            ?.map(it => _.omit(it, 'members')),
        };
      });
      setMembers(membersWithTeam);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeamListStatus, getMemberListStatus]);

  useEffect(() => {
    if (!_.isEmpty(teamProposalForm.formState.errors)) {
      dispatch(setErrorMessages(['Form validation has error!']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamProposalForm.formState.submitCount]);

  const handleSaveData = formData => {
    formData.start_date = convertDate(formData.start_date);
    formData.end_date = convertDate(formData.end_date);

    const formDataParams = { ...formData };
    formDataParams.members = memberList.map(it => _.omit(it, ['id']));
    if (isCreateProposal) {
      dispatch(createTeamProposalRequest(formDataParams));
    } else {
      formDataParams.id = params.id;
      dispatch(updateTeamProposalRequest(formDataParams));
    }
  };

  const handleResetData = () => {
    teamProposalForm.reset();
    setMemberList(teamProposal?.members ?? []);
  };
  const [showMemberDialog, setShowMemberDialog] = useState<boolean>(false);
  const [memberList, setMemberList] = useState<any>([]);
  return (
    <>
      <Helmet>
        <title>
          {isCreateProposal ? 'Create Team Proposal' : 'Edit Team Proposal'}
        </title>
        <meta name="description" content="Customer Management" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              id="proposal-form"
              component="form"
              onSubmit={teamProposalForm.handleSubmit(handleSaveData)}
              style={{ borderRadius: '12px' }}
            >
              <ProposalForm
                isCreateProposal={!!!params.id}
                teamProposalForm={teamProposalForm}
                handleSaveData={handleSaveData}
                handleResetData={handleResetData}
                formData={formData}
              />
            </Box>

            {members.length > 0 && (
              <Box pt={5} pb={5}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  mb={3}
                >
                  <Typography variant="h5">Team Members</Typography>
                  <Button
                    onClick={() => setShowMemberDialog(true)}
                    variant="outlined"
                  >
                    <AddIcon />
                    {isCreateProposal ? 'Add Members' : 'Edit Members'}
                  </Button>
                </Stack>

                <MemberListTable
                  memberList={memberList}
                  projectRole={project_role_metadata}
                  allMembers={member_meta_data}
                />
                <MemberDialogForm
                  projectRole={project_role_metadata}
                  allMembers={members}
                  selectedMembers={memberList}
                  isCreateProposal={isCreateProposal}
                  open={showMemberDialog}
                  onClose={() => {
                    setShowMemberDialog(false);
                  }}
                  onSaveData={setMemberList}
                  teams={listTeam?.data}
                />
              </Box>
            )}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}
