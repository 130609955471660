import React, { useState, useEffect } from 'react';

// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Pagination,
  FormLabel,
  Autocomplete,
  Chip,
} from '@mui/material';

import FilterBar from './components/FilterBar';

import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';

import { Email as EmailIcon } from '@mui/icons-material';

import LoadingButton from '@mui/lab/LoadingButton';

import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { RequestStatus } from 'constants/API';
import { selectMember } from './slice/selectors';
import { useMemberSlice } from './slice';
import { IMemberSchema } from './slice/types';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';

// Library
import { Link, useLocation } from 'react-router-dom';
import Path from 'config/clientPath';
import { REGEX_VALIDATE_EMAIL, STATUS_LIST } from 'constants/common';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { AvatarMember } from 'app/components/Avatar';
import { MemberLink } from 'app/components/MemberLink';
interface IEmail {
  id: number;
  email: string;
}
export function MemberPage() {
  const dispatch = useDispatch();
  const {
    actions: {
      getMemberListRequest,
      deleteMemberInfoRequest,
      getInviteMemberRequest,
      inviteMemberRequest,
      resetMemberInfoRequestStatus,
      getProjectRoleListRequest,
    },
  } = useMemberSlice();

  const {
    listMember,
    inviteList,
    getMemberListStatus,
    deleteMemberInfoStatus,
    inviteMemberStatus,
    getInviteMemberStatus,
    project_role_metadata,
    getProjectRoleListStatus,
    errorMess,
  } = useSelector(selectMember);

  const { setBreadcumbs, setSuccessMessages, setErrorMessages } =
    useGlobalSlice().actions;

  const location = useLocation();
  const history = useHistory();

  const { reset, register, handleSubmit, watch } = useForm();
  const [selectedEmails, setSelectedEmails] =
    React.useState<(IEmail | string)[]>();
  const [correctEmail, setCorrectEmail] = React.useState<IEmail>();
  const [allRoles, setAllRoles] = useState<any>([]);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showInviteDialog, setShowInviteDialog] = useState<boolean>(false);
  const [currentMemberTab, setCurrentMemberTab] = React.useState<string | null>(
    'LIST_TAB',
  );
  const [dataItem, setDataItem] = useState<IMemberSchema | null>();
  const [page, setPage] = React.useState(1);
  const [pageInvite, setPageInvite] = React.useState(1);
  const isLoading = checkIsRequesting([
    getMemberListStatus,
    getInviteMemberStatus,
  ]);

  const reloadData = () => {
    const params = new URLSearchParams(window.location.search);
    dispatch(
      getMemberListRequest({
        search: params.get('search'),
        role: params.get('role'),
        status: params.get('status'),
        full_name: params.get('full_name'),
        page: Math.abs(_.toInteger(params.get('page')) || 1),
        paging: true,
      }),
    );
  };
  const handleChangeMemberTab = (event, currentMemberTab) => {
    let params = queryString.parse(window.location.search);
    if (currentMemberTab) {
      params = { ...params, tab: currentMemberTab, page: 1 };
      setCurrentMemberTab(currentMemberTab);
    }
    const qs = queryString.stringify(params);
    history.push(`${Path.MEMBERS}?${qs}`);
  };
  useEffect(() => {
    if (getProjectRoleListStatus === RequestStatus.SUCCESS) {
      setAllRoles(_.keyBy(project_role_metadata, 'id'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectRoleListStatus]);

  useEffect(() => {
    if (!showInviteDialog) reset({ email: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInviteDialog]);

  useEffect(() => {
    if (deleteMemberInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Delete member success']));
      dispatch(resetMemberInfoRequestStatus());
      reloadData();
    }
    if (deleteMemberInfoStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Delete member errors']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMemberInfoStatus]);

  useEffect(
    () => () => {
      dispatch(resetMemberInfoRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  useEffect(() => {
    if (inviteMemberStatus === RequestStatus.SUCCESS) {
      setShowInviteDialog(false);
      dispatch(setSuccessMessages(['Invite email has been sent!']));
    }
    if (inviteMemberStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(errorMess));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteMemberStatus]);

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: 'Members' }]));
    dispatch(getProjectRoleListRequest({}));
    const params = new URLSearchParams(window.location.search);
    setPage(_.toInteger(params.get('page') || 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newPage = Math.abs(_.toInteger(params.get('page')));
    const newMemberTab = params.get('tab');
    setCurrentMemberTab(newMemberTab ? newMemberTab : 'LIST_TAB');
    if (newMemberTab && newMemberTab === 'INVITE_TAB') {
      setPageInvite(newPage);
      dispatch(
        getInviteMemberRequest({
          page: Math.abs(_.toInteger(params.get('page')) || 1),
        }),
      );
    } else {
      reloadData();
      setPage(newPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  useEffect(() => {
    if (REGEX_VALIDATE_EMAIL.test(watch('email'))) {
      setCorrectEmail({ id: 1, email: watch('email') });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('email')]);

  useEffect(() => {
    setSelectedEmails([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    let params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.MEMBERS}?${qs}`);
    setPage(newPage);
  };
  const handleChangePageInvite = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    let params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.MEMBERS}?${qs}`);
    setPageInvite(newPage);
  };

  const onInviteMember = () => {
    dispatch(inviteMemberRequest(selectedEmails));
  };

  return (
    <>
      <Helmet>
        <title>Member Management</title>
        <meta name="description" content="Customer Management" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                Member management
              </Typography>
              <Button
                variant="contained"
                startIcon={<EmailIcon />}
                onClick={() => {
                  setSelectedEmails([]);
                  setShowInviteDialog(true);
                }}
              >
                Invite new member
              </Button>
            </Box>

            <FilterBar
              currentMemberTab={currentMemberTab}
              handleChangeMemberTab={handleChangeMemberTab}
            />

            <Dialog open={showInviteDialog} fullWidth maxWidth="xs">
              <DialogTitle>Invite new member</DialogTitle>
              <DialogContent>
                <Box
                  component="form"
                  onSubmit={handleSubmit(onInviteMember)}
                  id="invite_form"
                >
                  <FormLabel>Email</FormLabel>
                  <Autocomplete
                    value={selectedEmails}
                    noOptionsText="Email not valid"
                    multiple
                    limitTags={4}
                    freeSolo
                    size="small"
                    options={correctEmail ? [correctEmail] : []}
                    filterSelectedOptions
                    getOptionLabel={it =>
                      _.get(it, 'email') ? _.get(it, 'email') : it
                    }
                    onChange={(e, newValue) => {
                      setCorrectEmail(undefined);
                      if (REGEX_VALIDATE_EMAIL.test(watch('email'))) {
                        let selectedEmail = _.cloneDeep(newValue);
                        let newSelectedEmail = _.reduce(
                          selectedEmail,
                          function (result, value, key) {
                            result.push({
                              id: key + 1,
                              email: _.get(value, 'email')
                                ? _.get(value, 'email')
                                : value,
                            });
                            return result;
                          },
                          [],
                        );
                        setSelectedEmails(newSelectedEmail);
                      }
                    }}
                    renderTags={(value, getTagProps) => {
                      return value.map((option: any, index: number) => (
                        <Chip
                          label={option.email}
                          {...getTagProps({ index })}
                        ></Chip>
                      ));
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size="small"
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        {...register('email')}
                      />
                    )}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  onClick={() => setShowInviteDialog(false)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={inviteMemberStatus === RequestStatus.REQUESTING}
                  loadingPosition="start"
                  startIcon={<EmailIcon />}
                  form="invite_form"
                  type="submit"
                >
                  Send Invite
                </LoadingButton>
              </DialogActions>
            </Dialog>

            <Dialog open={showConfirm}>
              <DialogTitle>Delete Member info</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Do you want to delete Member info bellow?
                </DialogContentText>
                <Typography variant="subtitle1" component="div">
                  Name: {dataItem?.full_name}
                </Typography>
                <Typography variant="subtitle1" component="div">
                  Email: {dataItem?.email}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowConfirm(false)}>Close</Button>
                <Button
                  color="error"
                  onClick={() => {
                    setShowConfirm(false);
                    dispatch(deleteMemberInfoRequest(dataItem?.id));
                  }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            {currentMemberTab === 'LIST_TAB' && (
              <>
                <WrapTable>
                  <TableContainer component={Paper}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell width={60}>No.</TableCell>
                          <TableCell sx={{ minWidth: 140 }} width={240}>
                            Name
                          </TableCell>
                          <TableCell sx={{ minWidth: 180 }}>Email</TableCell>
                          <TableCell sx={{ minWidth: 120 }}>Phone</TableCell>
                          <TableCell sx={{ minWidth: 120 }}>Role</TableCell>
                          <TableCell sx={{ minWidth: 90 }} width={220}>
                            Status
                          </TableCell>
                          <TableCell width={100}>Action</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {listMember?.data.map((item, idx) => {
                          return (
                            <TableRow key={`Member_row_${idx}`}>
                              <TableCell>
                                {(pageInvite - 1) *
                                  (listMember?.page_size || 0) +
                                  idx +
                                  1}
                              </TableCell>
                              <TableCell>
                                <Stack
                                  direction="row"
                                  spacing={'6px'}
                                  alignItems="center"
                                >
                                  <AvatarMember
                                    avatar={`${item?.avatar || ''}`}
                                    title={`${item?.full_name || ''}`}
                                    sx={{ width: 24, height: 24 }}
                                    member_id={item?.id}
                                  ></AvatarMember>
                                  <MemberLink
                                    member_id={item?.id}
                                    member_name={item?.full_name}
                                  ></MemberLink>
                                </Stack>
                              </TableCell>
                              <TableCell>{item.email}</TableCell>
                              <TableCell>{item.phone}</TableCell>
                              <TableCell>
                                {item?.role
                                  ? allRoles?.[item.role]?.name || ''
                                  : ''}
                              </TableCell>
                              <TableCell>{STATUS_LIST[item.status]}</TableCell>
                              <TableCell align="right">
                                <Stack direction="row" spacing={1}>
                                  <IconButton
                                    size="small"
                                    component={Link}
                                    to={`${Path.MEMBERS}/edit/${item.id}`}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => {
                                      setDataItem(item);
                                      setShowConfirm(true);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </WrapTable>
                <Stack spacing={2} alignItems={'flex-end'}>
                  <Pagination
                    page={page}
                    color="primary"
                    count={listMember?.total_page || 0}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePage}
                  />
                </Stack>
              </>
            )}
            {currentMemberTab === 'INVITE_TAB' && (
              <>
                <WrapTable>
                  <TableContainer component={Paper}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell width={60}>No.</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {inviteList?.data.map((item, idx) => {
                          return (
                            <TableRow key={`Member_row_${idx}`}>
                              <TableCell>
                                {(page - 1) * (listMember?.page_size || 0) +
                                  idx +
                                  1}
                              </TableCell>
                              <TableCell>{item.email}</TableCell>
                              <TableCell>{item.status}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </WrapTable>
                <Stack spacing={2} alignItems={'flex-end'}>
                  <Pagination
                    page={pageInvite}
                    color="primary"
                    count={inviteList?.total_page || 0}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChangePageInvite}
                  />
                </Stack>
              </>
            )}
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 230px);
  }
`;
