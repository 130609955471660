import { PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'constants/API';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import teamProposalSaga from './saga';
import { TeamProposalState } from './types';

export const initialState: TeamProposalState = {
  getTeamProposalListStatus: RequestStatus.IDLE,
  createTeamProposalStatus: RequestStatus.IDLE,
  getTeamProposalStatus: RequestStatus.IDLE,
  updateTeamProposalStatus: RequestStatus.IDLE,
  deleteTeamProposalStatus: RequestStatus.IDLE,
  teamProposal: null,
  listTeamProposal: null,
};

const slice = createSlice({
  name: 'proposal',
  initialState,
  reducers: {
    getTeamProposalListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getTeamProposalListStatus = RequestStatus.REQUESTING;
    },
    getTeamProposalListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getTeamProposalListStatus = RequestStatus.SUCCESS;
        state.listTeamProposal = action.payload;
      }
    },
    getTeamProposalListFail: (state, action: PayloadAction<any>) => {
      state.getTeamProposalListStatus = RequestStatus.ERROR;
    },
    createTeamProposalRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createTeamProposalStatus = RequestStatus.REQUESTING;
    },
    createTeamProposalSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createTeamProposalStatus = RequestStatus.SUCCESS;
    },
    createTeamProposalFail: (state, action: PayloadAction<any>) => {
      state.createTeamProposalStatus = RequestStatus.ERROR;
    },
    getTeamProposalRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getTeamProposalStatus = RequestStatus.REQUESTING;
    },
    getTeamProposalSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getTeamProposalStatus = RequestStatus.SUCCESS;
        state.teamProposal = action.payload;
      }
    },
    getTeamProposalFail: (state, action: PayloadAction<any>) => {
      state.getTeamProposalStatus = RequestStatus.ERROR;
    },
    updateTeamProposalRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateTeamProposalStatus = RequestStatus.REQUESTING;
    },
    updateTeamProposalSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateTeamProposalStatus = RequestStatus.SUCCESS;
    },
    updateTeamProposalFail: (state, action: PayloadAction<any>) => {
      state.updateTeamProposalStatus = RequestStatus.ERROR;
    },
    deleteTeamProposalRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteTeamProposalStatus = RequestStatus.REQUESTING;
    },
    deleteTeamProposalSuccess: (state, action: PayloadAction<any>) => {
      state.deleteTeamProposalStatus = RequestStatus.SUCCESS;
    },
    deleteTeamProposalFail: (state, action: PayloadAction<any>) => {
      state.deleteTeamProposalStatus = RequestStatus.ERROR;
    },
    resetTeamProposalRequestStatus: state => {
      state.getTeamProposalListStatus = RequestStatus.IDLE;
      state.getTeamProposalStatus = RequestStatus.IDLE;
      state.createTeamProposalStatus = RequestStatus.IDLE;
      state.updateTeamProposalStatus = RequestStatus.IDLE;
      state.deleteTeamProposalStatus = RequestStatus.IDLE;
    },
  },
});

export default slice.actions;

export const useTeamProposalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: teamProposalSaga });
  return { actions: slice.actions };
};
