import { useEffect } from 'react';
// UI
import { Helmet } from 'react-helmet-async';
import { Container } from 'app/components/Container';
import { Wrapper, Content } from 'app/components/Wrapper';
import BasicInfo from './components/BasicInfo';
import { Paper } from '@mui/material';
import { PageTitle } from 'app/components/PageTitle';
// Library
import _ from 'lodash';
import { useForgotPasswordSlice } from 'app/pages/ForgotPasswordPage/slice/';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from './slice/selectors';
import { useProfileSlice } from './slice';
import { convertDate } from 'utils/moment';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { selectForgotPassword } from 'app/pages/ForgotPasswordPage/slice/selectors';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import { RequestStatus } from 'constants/API';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';

/**
 *
 * ProfilePage
 *
 */
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { checkIsRequesting } from 'utils/helper';
import { Loader } from 'app/components/Loader';

export const ProfilePage = () => {
  const { userSessionData } = useSelector(selectGlobal);
  const dispatch = useDispatch();
  const { actions } = useProfileSlice();
  const profileForm = useForm();
  const changePasswordForm = useForm();
  const { info, getProfileStatus } = useSelector(selectProfile);
  const { setSuccessMessages, setErrorMessages, setBreadcumbs } =
    useGlobalSlice().actions;
  const { changePasswordRequest, resetChangePasswordRequestStatus } =
    useForgotPasswordSlice().actions;

  const {
    actions: {
      resetMemberInfoRequestStatus,
      updateMemberInfoRequest,
      uploadMemberAvatarRequest,
    },
  } = useMemberSlice();

  useEffect(
    () => () => {
      dispatch(resetMemberInfoRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ['componentWillUnMount'],
  );

  const { updateMemberInfoStatus, uploadMemberAvatarStatus } =
    useSelector(selectMember);
  const { changePasswordStatus } = useSelector(selectForgotPassword);

  const isLoading = checkIsRequesting([getProfileStatus]);

  useEffect(() => {
    if (updateMemberInfoStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Member update successful!']));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (updateMemberInfoStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Member update fail!']));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (uploadMemberAvatarStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Member avatar update successful!']));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (uploadMemberAvatarStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['This avatar is too big!']));
      dispatch(resetMemberInfoRequestStatus());
    }
    if (changePasswordStatus === RequestStatus.SUCCESS) {
      dispatch(setSuccessMessages(['Change password successful!']));
      changePasswordForm.setValue('confirm_password', '');
      changePasswordForm.setValue('new_password', '');
      changePasswordForm.setValue('old_password', '');
      dispatch(resetChangePasswordRequestStatus());
    }
    if (changePasswordStatus === RequestStatus.ERROR) {
      dispatch(setErrorMessages(['Wrong password!']));
      changePasswordForm.setValue('confirm_password', '');
      changePasswordForm.setValue('new_password', '');
      changePasswordForm.setValue('old_password', '');
      dispatch(resetChangePasswordRequestStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMemberInfoStatus, changePasswordStatus, uploadMemberAvatarStatus]);

  useEffect(() => {
    dispatch(actions.getProfileRequest(userSessionData.profile.id));
    dispatch(setBreadcumbs([{ title: 'User Profile' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (info) {
      const data = _.cloneDeep(info);
      profileForm.reset(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProfileStatus]);

  const handleSaveData = formData => {
    formData.avatar = null;
    formData.birthday = convertDate(formData.birthday);
    formData.join_date = convertDate(formData.join_date);
    formData.resign_date = convertDate(formData.resign_date);

    const formDataParams = { ...formData };
    // remove params of other form to avoid error when save data
    if (!formDataParams.first_name) {
      delete formDataParams.first_name;
    }
    if (!formDataParams.last_name) {
      delete formDataParams.last_name;
    }
    if (!formDataParams.role) {
      delete formDataParams.role;
    }
    dispatch(
      updateMemberInfoRequest({ objectId: info?.id, formData: formDataParams }),
    );
  };
  const handleChangePassword = formData => {
    dispatch(changePasswordRequest(formData));
  };
  const callAPIUploadImage = () => {
    let avatar = profileForm.getValues('avatar');
    if (avatar) {
      dispatch(uploadMemberAvatarRequest({ id: info?.id, avatar }));
    }
  };

  return (
    <>
      <Helmet>
        <title>Profile Info</title>
        <meta name="description" content="Profile Info" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <PageTitle>User Profile</PageTitle>
            <Paper
              id="profile-form"
              component="form"
              style={{ padding: '24px 100px', borderRadius: '12px' }}
            >
              <BasicInfo
                memberForm={profileForm}
                handleSaveData={handleSaveData}
                handleChangePassword={handleChangePassword}
                changePasswordForm={changePasswordForm}
                callAPIUploadImage={callAPIUploadImage}
              />
            </Paper>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
};
