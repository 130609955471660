import { Box, Divider, Stack, Typography } from '@mui/material';
import { AvatarMember } from 'app/components/Avatar';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { ReactComponent as ContactIcon } from 'assets/icons/profile/contact.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/profile/email.svg';
import { ReactComponent as PushpinIcon } from 'assets/icons/profile/pushpin.svg';
import _ from 'lodash';
import React from 'react';
// Redux & Sagas
import { useSelector } from 'react-redux';
// UI
import styled from 'styled-components/macro';

export default function Profile(props: { memberInfo?: any }) {
  const memberSlice = useSelector(selectMember);
  // get info from props or from reducer
  const memberInfo = props?.memberInfo
    ? props?.memberInfo
    : memberSlice.memberInfo;

  return (
    <ProfileContainer>
      <Stack direction="row" spacing={2} mb={3}>
        <Box>
          <AvatarMember
            title={memberInfo?.full_name}
            avatar={memberInfo?.avatar}
            size={40}
          ></AvatarMember>
        </Box>
        <Stack direction="column">
          <Box mt={1}>
            <Typography component="div" variant="h6">
              {memberInfo?.full_name}
            </Typography>
          </Box>
          <Stack
            direction="row"
            spacing={5}
            className="sub_profile_info"
            mt={2}
          >
            <Box>
              <EmailIcon />
              <Typography component="span" ml={1}>
                {memberInfo?.email}
              </Typography>
            </Box>
            {memberInfo?.role_name && (
              <Box>
                <ContactIcon />
                <Typography component="span" ml={1}>
                  {memberInfo?.role_name}
                </Typography>
              </Box>
            )}
            {!_.isEmpty(memberInfo?.skills) && (
              <Box>
                <PushpinIcon />
                <Typography component="span" ml={1}>
                  {_.map(memberInfo?.skills, 'name').join(', ')}
                </Typography>
              </Box>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Divider />
    </ProfileContainer>
  );
}

const ProfileContainer = styled.div`
  .avatar {
    width: 96px;
    height: 96px;
  }
  .sub_profile_info {
    svg {
      color: #437dff;
    }
  }
`;
