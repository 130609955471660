import React, { useEffect, useState } from 'react';

// UI
import { Box, Stack, Typography } from '@mui/material';

// Library
import styled from 'styled-components/macro';
import moment from 'moment';
import _ from 'lodash';
import { HourTag } from 'app/components/HourTag';
import { useDispatch, useSelector } from 'react-redux';
import { selectTimesheet } from '../slice/selectors';
import { RequestStatus } from 'constants/API';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';
import { useMemberSlice } from 'app/pages/MemberPage/slice';
import {
  APPROVED_TASK_STATUS,
  REPORT_TYPE,
  REQUESTING_TASK_STATUS,
} from 'constants/common';
import { TasksModal } from './TasksModal';
import { IMemberTasksSchema } from 'app/pages/MemberPage/slice/types';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

interface ICalendarViewMode {
  currentDate: string;
  viewMode: string;
  showDialogUpdateTask: Function;
  showModalAddTaskCalendar: Function;
}

export function CalendarViewMode(props: ICalendarViewMode) {
  const location = useLocation();
  const [allDates, setAllDates] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<moment.Moment | null>(null);
  const activeDate = currentDate
    ? currentDate.format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD');

  const [currentTasks, setCurrentTasks] = useState<IMemberTasksSchema[] | []>(
    [],
  );
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [showAllTaskModal, setShowAllTaskModal] = useState(false);
  const { showModalAddTaskCalendar, showDialogUpdateTask } = props;
  const dispatch = useDispatch();

  const countDatesCurrentMonth = moment(activeDate).daysInMonth();
  const monthStartDate = moment(activeDate).startOf('month');

  const weekDayOfStartMonth = monthStartDate.day();
  const prevMonthAppearStartDate = monthStartDate
    .clone()
    .subtract(weekDayOfStartMonth, 'days');
  const monthEndDate = moment(activeDate).endOf('month');
  const weekDayOfEndMonth = monthEndDate.day();

  const timesheetSlice = useSelector(selectTimesheet);
  const {
    addTimesheetStatus,
    requestApprovalStatus,
    cancelRequestStatus,
    updateTimesheetStatus,
  } = timesheetSlice;

  const {
    actions: { getMemberWorkReportRequest },
  } = useMemberSlice();

  const { memberTasks, monthWorkHoursReport, getMemberWorkReportStatus } =
    useSelector(selectMember);

  const changeDataByQuery = () => {
    let params = queryString.parse(window.location.search);
    const newDate = params?.date ? moment(params?.date) : moment();
    setCurrentDate(newDate);
  };

  const getDayOfMonth = tempDate => {
    return +tempDate.format('DD') === 1
      ? tempDate.format('MMM D')
      : +tempDate.format('DD');
  };

  const getAllDates = () => {
    const prevMonthDates = _.times(
      weekDayOfStartMonth,
      distanceFromStartMonth => {
        const tempDate = prevMonthAppearStartDate
          .clone()
          .add(distanceFromStartMonth, 'days');
        return {
          dateString: tempDate.format('YYYY-MM-DD'),
          dayOfMonth: getDayOfMonth(tempDate),
          isCurrentMonth: false,
          isPreviousMonth: true,
          reportDateString: tempDate.format('MM/DD'),
        };
      },
    );

    // day of Sunday: 0, Saturday: 6
    const nextMonthDates = _.times(
      6 - weekDayOfEndMonth,
      distanceFromStartMonth => {
        const tempDate = monthEndDate
          .clone()
          .add(1 + distanceFromStartMonth, 'days');
        return {
          dateString: tempDate.format('YYYY-MM-DD'),
          dayOfMonth: getDayOfMonth(tempDate),
          isCurrentMonth: false,
          isNextMonth: true,
          reportDateString: tempDate.format('MM/DD'),
        };
      },
    );

    const currentMonthDates = _.times(
      countDatesCurrentMonth,
      distanceFromStartMonth => {
        const tempDate = monthStartDate
          .clone()
          .add(distanceFromStartMonth, 'days');
        return {
          dateString: tempDate.format('YYYY-MM-DD'),
          dayOfMonth: getDayOfMonth(tempDate),
          isCurrentMonth: true,
          reportDateString: tempDate.format('MM/DD'),
        };
      },
    );
    const _allDates = [
      ...prevMonthDates,
      ...currentMonthDates,
      ...nextMonthDates,
    ];
    return _allDates;
  };

  useEffect(() => {
    if (
      addTimesheetStatus === RequestStatus.SUCCESS ||
      updateTimesheetStatus === RequestStatus.SUCCESS ||
      requestApprovalStatus === RequestStatus.SUCCESS ||
      cancelRequestStatus === RequestStatus.SUCCESS
    ) {
      reloadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addTimesheetStatus,
    requestApprovalStatus,
    cancelRequestStatus,
    updateTimesheetStatus,
  ]);

  useEffect(() => {
    changeDataByQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFirstRender && currentDate) {
      const newAllDates = getAllDates();
      setAllDates(newAllDates);
      setIsFirstRender(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentDate)]);

  const reloadData = () => {
    let params = queryString.parse(window.location.search);
    const newDate = (params?.date ? moment(params?.date) : moment()).format(
      'YYYY-MM-DD',
    );
    const tags = params?.tags || null;
    // recalculate date params
    const monthStartDate = moment(newDate).startOf('month');
    const weekDayOfStartMonth = monthStartDate.day();
    const prevMonthAppearStartDate = monthStartDate
      .clone()
      .subtract(weekDayOfStartMonth, 'days');
    const monthEndDate = moment(newDate).endOf('month');
    const weekDayOfEndMonth = monthEndDate.day();
    const nextMonthAppearEndDate = monthEndDate
      .clone()
      .add(6 - weekDayOfEndMonth, 'days');

    dispatch(
      getMemberWorkReportRequest({
        date_start: prevMonthAppearStartDate.format('yyyy-MM-DD'),
        date_end: nextMonthAppearEndDate.format('yyyy-MM-DD'),
        report_type: REPORT_TYPE.DAY,
        tags,
      }),
    );
  };

  useEffect(() => {
    changeDataByQuery();
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    if (getMemberWorkReportStatus === RequestStatus.SUCCESS) {
      const newAllDates = getAllDates();
      const taskLogs = _.reduce(
        memberTasks,
        (result, value) => {
          const color = _.head(value.tags)?.color || '#8c8c8c';
          (result[value?.date] || (result[value?.date] = [])).push({
            ...value,
            color,
          });
          return result;
        },
        {},
      );
      const hoursReportPerDate = _.keyBy(monthWorkHoursReport, 'date');
      const allDatesReport = newAllDates.map(dateReport => {
        const hours =
          hoursReportPerDate?.[dateReport.reportDateString]?.duration || 0;
        const tasks = taskLogs?.[dateReport.dateString] || [];
        const isShowViewMore = _.size(tasks) > 3 ? true : false;
        return { ...dateReport, hours, isShowViewMore, tasks };
      });
      setAllDates(allDatesReport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMemberWorkReportStatus]);

  const getDayClassName = day => {
    let className = '';
    className += day.isCurrentMonth ? '' : ' cl_outside';
    className =
      className +
      (day.dateString === moment().format('YYYY-MM-DD')
        ? ' cl_current_date'
        : '');
    return className;
  };

  return (
    <Wrapper>
      <Container>
        <TasksModal
          currentTasks={currentTasks}
          open={showAllTaskModal}
          onClose={() => setShowAllTaskModal(false)}
        ></TasksModal>
        <CalendarHead>
          {_.times(7, day => {
            return (
              <WeeklyHead>
                <Typography variant="h6">
                  {moment.weekdays(day).substr(0, 2)}
                </Typography>
              </WeeklyHead>
            );
          })}
        </CalendarHead>
        <CalendarMonthly>
          {allDates.map(day => (
            <CalendarDay
              key={day.dateString}
              className={getDayClassName(day)}
              onClick={() => {
                showModalAddTaskCalendar(day.dateString);
              }}
            >
              <Stack spacing={'5px'}>
                <Label>
                  <Typography
                    variant="h6"
                    color={
                      day.dateString === moment().format('YYYY-MM-DD')
                        ? '#fff'
                        : '#000'
                    }
                  >
                    {day.dayOfMonth}
                  </Typography>

                  <HourWork>
                    <HourTag
                      data={{
                        backgroundColor: '#BDD8FF',
                        label: `${day.hours || 0}h`,
                      }}
                      size="small"
                    ></HourTag>
                  </HourWork>
                </Label>
                {day?.tasks?.map((task, k) => {
                  if (!day.isShowViewMore || k < 2) {
                    return (
                      <Event
                        style={{
                          backgroundColor: task.color,
                          cursor: 'pointer',
                        }}
                        key={k}
                        onClick={event => {
                          event.stopPropagation();
                          let updatedTask = {
                            ...task,
                            report_date: day.dateString,
                            disabled_update:
                              task?.status ===
                              (APPROVED_TASK_STATUS || REQUESTING_TASK_STATUS)
                                ? true
                                : false,
                          };

                          showDialogUpdateTask(updatedTask);
                        }}
                      >
                        {task.description}
                      </Event>
                    );
                  }
                  return '';
                })}
                {day.isShowViewMore && (
                  <Event
                    className="view_more_task"
                    onClick={event => {
                      event.stopPropagation();
                      setShowAllTaskModal(true);
                      setCurrentTasks(day?.tasks);
                    }}
                  >
                    View more
                  </Event>
                )}
              </Stack>
            </CalendarDay>
          ))}
        </CalendarMonthly>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled(Box)``;

const Container = styled(Box)`
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
`;

const CalendarHead = styled(Box)`
  display: flex;
  height: 32px;
  align-items: center;
  background: #f0f7ff;
`;

const WeeklyHead = styled(Box)`
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
  flex: 1;
`;

const CalendarMonthly = styled(Box)`
  background: #fff;
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 130px;
`;

const CalendarDay = styled(Box)`
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
  &.cl_outside h6 {
    color: rgba(0, 0, 0, 0.3);
  }
  &.cl_current_date {
    background-color: #437dff;
  }
`;

const Label = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  margin-top: 5px;
`;

const Event = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
  background: #8c8c8c;
  height: 26px;
  line-height: 26px;
  margin-right: 3px !important;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
  &.view_more_task {
    cursor: pointer;
  }
`;

const HourWork = styled(Box)``;
