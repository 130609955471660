import { AddCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Container } from 'app/components/Container';
import { Loader } from 'app/components/Loader';
import { Content, Wrapper } from 'app/components/Wrapper';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/button/share.svg';
import Path from 'config/clientPath';
import { RequestStatus } from 'constants/API';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// UI
import { Helmet } from 'react-helmet-async';
// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
// Library
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { checkIsRequesting } from 'utils/helper';
import { useTeamProposalSlice } from './slice';
import { selectTeamProposal } from './slice/selectors';
import { ITeamProposalSchema } from './slice/types';

export function TeamProposalPage() {
  const dispatch = useDispatch();
  const {
    actions: {
      getTeamProposalListRequest,
      deleteTeamProposalRequest,
      resetTeamProposalRequestStatus,
    },
  } = useTeamProposalSlice();
  const {
    listTeamProposal,
    getTeamProposalListStatus,
    createTeamProposalStatus,
    updateTeamProposalStatus,
    deleteTeamProposalStatus,
  } = useSelector(selectTeamProposal);

  const { setBreadcumbs } = useGlobalSlice().actions;

  const [showConfirm, setShowConfirm] = useState(false);
  const [deleteTeamProposal, setDeleteTeamProposal] =
    useState<ITeamProposalSchema | null>();

  const [page, setPage] = useState<number>(0);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (
      createTeamProposalStatus === RequestStatus.SUCCESS ||
      updateTeamProposalStatus === RequestStatus.SUCCESS ||
      deleteTeamProposalStatus === RequestStatus.SUCCESS
    ) {
      dispatch(resetTeamProposalRequestStatus());
      dispatch(getTeamProposalListRequest({}));
    }
  }, [
    getTeamProposalListRequest,
    createTeamProposalStatus,
    updateTeamProposalStatus,
    deleteTeamProposalStatus,
    resetTeamProposalRequestStatus,
    dispatch,
  ]);

  const isLoading = checkIsRequesting([getTeamProposalListStatus]);

  useEffect(() => {
    dispatch(setBreadcumbs([{ title: 'Team Proposal' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    dispatch(
      getTeamProposalListRequest({
        page: Math.abs(_.toInteger(params.get('page')) || 1),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <>
      <Helmet>
        <title>Team Proposal</title>
        <meta name="description" content="Team Proposal" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                Team Proposal
              </Typography>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={`${Path.TEAM_PROPOSAL}/add`}
                startIcon={<AddCircle />}
              >
                Create new
              </Button>
            </Box>
            <Dialog open={showConfirm}>
              <DialogTitle>Delete Proposal</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Do you want to delete proposal bellow?
                </DialogContentText>
                <Typography variant="subtitle1" component="div">
                  Name: {deleteTeamProposal?.project}
                </Typography>
                <Typography variant="subtitle1" component="div">
                  Client: {deleteTeamProposal?.customer_name}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowConfirm(false)}>Close</Button>
                <Button
                  color="error"
                  onClick={() => {
                    setShowConfirm(false);
                    dispatch(deleteTeamProposalRequest(deleteTeamProposal?.id));
                  }}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 60 }} width={60}>
                      No
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }} width={200}>
                      Customer Name
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }} width={300}>
                      Project Name
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }} width={200}>
                      Title
                    </TableCell>
                    <TableCell sx={{ minWidth: 130 }} width={120}>
                      Created By
                    </TableCell>
                    <TableCell sx={{ minWidth: 110 }} width={120}>
                      Create Date
                    </TableCell>
                    <TableCell width={120}>Action</TableCell>
                  </TableRow>
                </TableHead>
                {getTeamProposalListStatus === RequestStatus.REQUESTING && (
                  <TableHead component={LinearProgress} />
                )}
                <TableBody>
                  {listTeamProposal?.data.map((item, idx) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell>{idx + 1}</TableCell>
                        <TableCell>{item.customer_name}</TableCell>
                        <TableCell>{item.project}</TableCell>
                        <TableCell>{item.title}</TableCell>
                        <TableCell>{item.created_by?.name}</TableCell>
                        <TableCell>
                          {moment.utc(item.created_at).format('YYYY-MM-DD')}
                        </TableCell>
                        <TableCell align="right">
                          <Stack direction="row" spacing={1}>
                            <IconButton
                              size="small"
                              component={Link}
                              to={`${Path.TEAM_PROPOSAL}/${item.uuid}`}
                            >
                              <ShareIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              component={Link}
                              to={`${Path.TEAM_PROPOSAL}/edit/${item.id}`}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => {
                                setDeleteTeamProposal(item);
                                setShowConfirm(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <TablePagination
                component="div"
                rowsPerPageOptions={[-1]}
                count={listTeamProposal?.total_records || 0}
                rowsPerPage={listTeamProposal?.page_size || 0}
                page={page}
                onPageChange={(
                  event: React.MouseEvent<HTMLButtonElement> | null,
                  page: number,
                ) => {
                  history.push(`${Path.TEAM_PROPOSAL}?page=${page + 1}`);
                  setPage(page);
                }}
                showFirstButton
                showLastButton
              />
            </TableContainer>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}
