import config from 'config';
import axios, { Method as AxiosMethod } from 'axios';
import { getToken } from 'utils/localStorage';
import Path from 'config/clientPath';
import {
  removeToken,
  removeUserCachedDataFromLocalStorage,
} from 'utils/localStorage';

export type PaletteMethod = Extract<
  AxiosMethod,
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH'
  | 'options'
  | 'OPTIONS'
>;

const mainRequestConfig = {
  // Mock baseURL is from a local Postman Mock Server
  baseURL: config.serviceBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const mainAxiosInstance = axios.create(mainRequestConfig);

mainAxiosInstance.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

mainAxiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { status } = error.response;
    if (status === 401) {
      removeToken();
      removeUserCachedDataFromLocalStorage();
      window.location.href = Path.LOGIN;
      return Promise.resolve(error);
    }
    return error.response;
  },
);

export const mainRequest = (
  url,
  payload,
  method: PaletteMethod,
  headers: Object = {
    'X-Requested-With': 'XMLHttpRequest',
  },
) => {
  const data = payload;
  let params;
  if (method === 'get') {
    params = payload;
  }
  return mainAxiosInstance({ url, data, params, method, headers });
};

const APIs = {
  /* <CUSTOMER> */
  getCustomerList: payload => mainRequest('/customers/', payload, 'get'),
  createCustomerInfo: payload => mainRequest('/customers/', payload, 'post'),
  updateCustomerInfo: payload =>
    mainRequest(`/customers/${payload.id}/`, payload, 'put'),
  deleteCustomerInfo: payload =>
    mainRequest(`/customers/${payload}/`, null, 'delete'),
  uploadCustomerLogo: (id, payload) =>
    mainRequest(`/customers/${id}/upload/`, payload, 'put', {
      'content-type': 'multipart/form-data',
    }),
  /* </CUSTOMER> */

  /* <TEAM> */
  getTeamList: payload => mainRequest('/teams/', payload, 'get'),
  createTeamInfo: payload => mainRequest('/teams/', payload, 'post'),
  updateTeamInfo: (id, payload) => mainRequest(`/teams/${id}/`, payload, 'put'),
  deleteTeamInfo: payload => mainRequest(`/teams/${payload}/`, null, 'delete'),

  getSkillList: payload => mainRequest('/skills/', payload, 'get'),
  getLanguageList: payload => mainRequest('/languages/', payload, 'get'),
  getProjectRoleList: payload => mainRequest('/roles/', payload, 'get'),
  getTeamRoleList: payload => mainRequest('/teams/roles/', payload, 'get'),
  /* </TEAM> */

  /* <AUTH> */
  login: payload => mainRequest('/login/', payload, 'post'),
  checkExpiredLinkForgotPassword: payload =>
    mainRequest(
      `/password-reset/${payload.uidb64}/${payload.token}/`,
      [],
      'get',
    ),
  forgotPassword: payload =>
    mainRequest('/request-reset-email/', payload, 'post'),
  changePassword: payload => mainRequest('/change_password/', payload, 'post'),
  resetPassword: payload =>
    mainRequest(
      `/password-reset/${payload.uidb64}/${payload.token}/`,
      payload.payloadData,
      'post',
    ),
  /* </AUTH> */

  /* <PROJECTS> */
  getProjectSummary: payload =>
    mainRequest('/projects/summary/', payload, 'get'),
  getProjectList: payload => mainRequest('projects/', payload, 'get'),
  getProject: payload =>
    mainRequest(`/projects/${payload.id}/`, payload, 'get'),
  createProject: payload => mainRequest('/projects/', payload, 'post'),
  updateProject: payload =>
    mainRequest(`/projects/${payload.id}/`, payload, 'put'),
  deleteProject: payload =>
    mainRequest(`/projects/${payload}/`, undefined, 'delete'),
  uploadProjectImage: (id, payload) =>
    mainRequest(`/projects/${id}/upload/`, payload, 'put', {
      'content-type': 'multipart/form-data',
    }),
  /* </PROJECTS> */

  /* <TAGS> */
  getTags: payload => mainRequest('work_tags/', payload, 'get'),
  /* </TAGS> */

  /* <TIMESHEET> */
  getTimesheet: payload => mainRequest('work_reports/', payload, 'get'),
  addTimesheet: payload => mainRequest('work_reports/', payload, 'post'),
  updateTimesheet: (id, payload) =>
    mainRequest(`/work_reports/${id}/`, payload, 'put'),
  deleteTimesheet: payload =>
    mainRequest(`work_reports/${payload}/`, null, 'delete'),
  requestApproval: payload =>
    mainRequest('work_reports/request_approval/', payload, 'post'),
  cancelRequest: payload =>
    mainRequest('work_reports/cancel_request/', payload, 'post'),
  approveRequest: payload =>
    mainRequest('work_reports/approval/', payload, 'post'),
  getApprovalList: payload =>
    mainRequest('work_reports/approval_list/', payload, 'get'),
  /* </TIMESHEET> */

  /* <INVITE> */
  createMemberFromInvite: payload => mainRequest('/members/', payload, 'post'),
  getInviteMetadata: payload => mainRequest('/invite/', payload, 'get'),
  /* </INVITE> */

  /* <MEMBER> */
  getMemberList: payload => mainRequest('/members/', payload, 'get'),
  getMemberInfo: payload => mainRequest(`/members/${payload}/`, null, 'get'),
  getMemberCurrentAssignment: payload =>
    mainRequest(`/members/${payload}/assignment/current/`, null, 'get'),
  getMemberHistoryAssignment: payload =>
    mainRequest(`/members/${payload}/assignment/history/`, null, 'get'),
  createMemberInfo: payload => mainRequest('/members/', payload, 'post'),
  updateMemberInfo: (id, payload) =>
    mainRequest(`/members/${id}/`, payload, 'put'),
  deleteMemberInfo: payload =>
    mainRequest(`/members/${payload}/`, null, 'delete'),
  inviteMember: payload => mainRequest('/invite/', payload, 'post'),
  getInviteMember: payload => mainRequest('/invite/list/', payload, 'get'),
  uploadMemberAvatar: (id, payload) =>
    mainRequest(`/members/${id}/avatar`, payload, 'put', {
      'content-type': 'multipart/form-data',
    }),
  /* </MEMBER> */
  /* <ASSIGNMENT> */
  getListAssignment: payload => mainRequest('/assignment/', payload, 'get'),
  updateAssignmentInfo: (id, payload) =>
    mainRequest(`/assignment/${id}/`, payload, 'put'),
  createAssignmentInfo: payload => mainRequest('/assignment/', payload, 'post'),
  deleteAssignmentInfo: payload =>
    mainRequest(`/assignment/${payload}/`, null, 'delete'),
  /* </ASSIGNMENT> */

  getListScope: () => mainRequest('/skills/', undefined, 'get'),

  /* <Report> */
  getWorkDetailReport: payload =>
    mainRequest('work_reports/detail_report/', payload, 'get'),
  getWorkEffortReport: payload =>
    mainRequest('work_reports/effort/', payload, 'get'),
  getDateRangeWorkReport: payload =>
    mainRequest('work_reports/date_range/', payload, 'get'),
  getAssignProjects: payload =>
    mainRequest('work_reports/projects/', payload, 'get'),
  getAllProjects: payload =>
    mainRequest('work_reports/approval/metadata/projects/', payload, 'get'),
  getAllMembers: payload =>
    mainRequest('work_reports/approval/metadata/members/', payload, 'get'),
  /* </Report> */

  /* <Assignment> */
  getAssignmentSummary: payload =>
    mainRequest('/assignment/summary', payload, 'get'),
  getAssignmentDetail: payload =>
    mainRequest(`/assignment/summary/${payload.id}/`, payload, 'get'),
  updateCommitEffort: payload =>
    mainRequest(`/assignment/commit_effort/`, payload, 'post'),
  /* </Assignment> */

  /* <LandingPage> */
  getLandingPageProjects: payLoad =>
    mainRequest('/lp/projects/', payLoad, 'get'),
  getLandingPageCustomers: payLoad =>
    mainRequest('/lp/customers/', payLoad, 'get'),
  getLandingPageMembers: payLoad => mainRequest('/lp/members/', payLoad, 'get'),
  getLandingPageTechnologies: payLoad =>
    mainRequest('/lp/technologies/', payLoad, 'get'),
  /* </LandingPage> */
  /* <PROPOSAL> */
  getTeamProposalList: payload => mainRequest('proposals/', payload, 'get'),
  createTeamProposal: payload => mainRequest('proposals/', payload, 'post'),
  getTeamProposal: payload => mainRequest(`proposals/${payload}/`, null, 'get'),
  getCVInfo: payload =>
    mainRequest(`proposals/${payload.uuid}/${payload.id}`, null, 'get'),
  updateTeamProposal: payload =>
    mainRequest(`proposals/${payload.id}/`, payload, 'put'),
  deleteTeamProposal: payload =>
    mainRequest(`proposals/${payload}/`, null, 'delete'),
  /* </PROPOSAL> */
};

export default APIs;
