import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

// UI
import {
  DialogTitle,
  DialogContent,
  Grid,
  Stack,
  Typography,
  Button,
  FormLabel,
  TextField,
  DialogActions,
  Dialog,
} from '@mui/material';

import { ReactComponent as DefaultImage } from 'assets/placeholder/128.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/button/upload.svg';

import { LoadingIndicator } from 'app/components/LoadingIndicator';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useCustomerSlice } from '../../slice';
import { selectCustomer } from '../../slice/selectors';
import { RequestStatus } from 'constants/API';
import { ICustomerSchema } from '../../slice/types';

// Library
import styled from 'styled-components/macro';
import { useForm } from 'react-hook-form';

export function DialogForm({ ...props }) {
  const dispatch = useDispatch();
  const { actions } = useCustomerSlice();
  const customerSlice = useSelector(selectCustomer);

  const [isEditMode, setIsEditMode] = useState(false);

  const { data, onClose } = props as {
    data: ICustomerSchema;
    onClose: () => Dispatch<SetStateAction<boolean>>;
  };
  const {
    reset,
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setIsEditMode(props.data ? true : false);
  }, [props.data]);

  useEffect(() => {
    reset({
      id: data?.id,
      url: data?.url,
      code: data?.code,
      name: data?.name,
      note: data?.note,
      phone: data?.phone,
      address: data?.address,
      description: data?.description,
      sale_partner: data?.sale_partner,
    });
  }, [isEditMode, data, reset]);

  useEffect(() => {
    if (
      customerSlice.createCustomerInfoStatus === RequestStatus.SUCCESS ||
      customerSlice.updateCustomerInfoStatus === RequestStatus.SUCCESS
    ) {
      reset();
      onClose();
    }
    if (
      customerSlice.createCustomerInfoStatus === RequestStatus.ERROR ||
      customerSlice.updateCustomerInfoStatus === RequestStatus.ERROR
    ) {
      if (customerSlice.listError) {
        for (var field_name in customerSlice.listError) {
          var message = customerSlice.listError[field_name];
          setError(field_name, {
            type: 'manual',
            message: message,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerSlice.createCustomerInfoStatus,
    customerSlice.updateCustomerInfoStatus,
    onClose,
  ]);

  const onSave = formData => {
    if (formData.id) {
      dispatch(actions.updateCustomerInfoRequest(formData));
    } else {
      dispatch(actions.createCustomerInfoRequest(formData));
    }
  };

  const uploadedLogo = watch('logo');

  return (
    <CustomerForm open={props.open} maxWidth="md" fullWidth scroll="paper">
      <DialogTitle>
        {isEditMode ? 'UPDATE CUSTOMER INFO' : 'ADD NEW CUSTOMER'}
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          mb={2}
          pt={2}
          spacing={2}
          component="form"
          id="customer-form"
          onSubmit={handleSubmit(onSave)}
        >
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              {uploadedLogo?.length > 0 ? (
                <img
                  src={URL.createObjectURL(uploadedLogo[0])}
                  alt={uploadedLogo[0].name}
                  className="img-thumbnail mb-2"
                  width={120}
                  height={120}
                />
              ) : props.data?.logo_url ? (
                <img
                  src={props.data.logo_url}
                  className="img-thumbnail mb-2"
                  alt={props.data?.name}
                  width={120}
                  height={120}
                />
              ) : (
                <DefaultImage />
              )}
              <input
                id="image-input-file"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                {...register('logo')}
              />
              {errors.logo?.message}
              <Stack spacing={2} alignSelf="flex-end">
                <Typography>
                  Support for a single upload, size limit: 10MB.
                  <br />
                  Allowed file types: PNG, JPG, SVG
                </Typography>
                <label htmlFor="image-input-file">
                  <Button
                    variant="outlined"
                    startIcon={<UploadIcon />}
                    component="span"
                  >
                    Upload
                  </Button>
                </label>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <FormLabel>Code</FormLabel>
            <TextField
              margin="dense"
              placeholder="Enter Code"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.code ? true : false}
              {...register<keyof ICustomerSchema>('code', {
                required: 'This is required.',
                maxLength: 255,
              })}
              helperText={errors.code?.message}
            />
          </Grid>

          <Grid item xs={8}>
            <FormLabel>Customer Name</FormLabel>
            <TextField
              margin="dense"
              placeholder="Enter Name"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.name ? true : false}
              {...register<keyof ICustomerSchema>('name', {
                required: 'This is required.',
                maxLength: 255,
              })}
              helperText={errors.name?.message}
            />
          </Grid>

          <Grid item xs={4}>
            <FormLabel>Phone</FormLabel>
            <TextField
              margin="dense"
              placeholder="Phone number"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.phone ? true : false}
              {...register<keyof ICustomerSchema>('phone', {
                required: 'This is required.',
                maxLength: 20,
              })}
              helperText={errors.phone?.message}
            />
          </Grid>
          <Grid item xs={8}>
            <FormLabel>URL</FormLabel>
            <TextField
              margin="dense"
              placeholder="ex: https://example.com"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.url ? true : false}
              {...register<keyof ICustomerSchema>('url', {
                required: 'This is required.',
                maxLength: 255,
              })}
              helperText={errors.url?.message}
            />
          </Grid>

          <Grid item xs={4}>
            <FormLabel>Sale Partner</FormLabel>
            <TextField
              margin="dense"
              placeholder="Sale Partner Name"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.sale_partner ? true : false}
              {...register<keyof ICustomerSchema>('sale_partner', {
                maxLength: 40,
              })}
              helperText={errors.sale_partner?.message}
            />
          </Grid>

          <Grid item xs={8}>
            <FormLabel>Address</FormLabel>
            <TextField
              margin="dense"
              placeholder="Enter Address"
              variant="outlined"
              size="small"
              fullWidth
              error={errors.address ? true : false}
              {...register<keyof ICustomerSchema>('address', {
                required: 'This is required.',
                maxLength: 255,
              })}
              helperText={errors.address?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <FormLabel>Description</FormLabel>
            <TextField
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={2}
              error={errors.description ? true : false}
              {...register<keyof ICustomerSchema>('description')}
              helperText={errors.description?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <FormLabel>Note</FormLabel>
            <TextField
              margin="dense"
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={3}
              error={errors.note ? true : false}
              {...register<keyof ICustomerSchema>('note', {
                maxLength: 2000,
              })}
              helperText={errors.note?.message}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          form="customer-form"
          variant="contained"
          disabled={
            customerSlice.createCustomerInfoStatus ===
              RequestStatus.REQUESTING ||
            customerSlice.updateCustomerInfoStatus === RequestStatus.REQUESTING
          }
        >
          {(customerSlice.createCustomerInfoStatus ===
            RequestStatus.REQUESTING ||
            customerSlice.updateCustomerInfoStatus ===
              RequestStatus.REQUESTING) && (
            <Typography component="span" mr={1}>
              <LoadingIndicator small />
            </Typography>
          )}
          {isEditMode ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </CustomerForm>
  );
}

const CustomerForm = styled(Dialog)`
  .MuiDialogContent-root {
    padding: 0px 24px !important;
  }
`;
