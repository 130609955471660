import React from 'react';

// UI
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { IWorkHoursInfoSchema } from 'app/pages/MemberPage/slice/types';

// Library
Chart.register(ChartDataLabels);
interface WorkReportProps {
  data: IWorkHoursInfoSchema;
  isViewer: boolean;
}

export default function WorkReport(props: WorkReportProps) {
  const { data } = props;

  const sizeOfChart = {
    width: 400,
    height: 70,
  };

  const chartData = {
    labels: data.labels,
    datasets: [
      {
        type: 'bar',
        label: 'Hours',
        backgroundColor: '#437DFF',
        data: data.data,
        borderColor: '#437DFF',
        borderWidth: 2,
      },
    ],
  };

  const options = {
    layout: {
      padding: {
        top: 20,
      },
    },
    plugins: {
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] > 0;
        },
        color: 'black',
        align: 'end',
        anchor: 'end',
        font: { size: '14' },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      <Bar data={chartData} {...sizeOfChart} options={options} />
    </>
  );
}
