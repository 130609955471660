import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import Slice from '.';
import actions from 'app/pages/GlobalContainer/slice/';
import roles from 'constants/roles';
import _ from 'lodash';
import { USER_ROLES } from 'constants/common';

function* loginFlow({ payload }) {
  let errorMess = ['Ops..Login failed!'];
  try {
    const response = yield call(APIs.login, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      let sessionData = response.data;
      let userRole = roles.IS_NORMAL_USER;
      _.forEach(USER_ROLES, role => {
        if (_.includes(sessionData.roles, role)) {
          userRole = role;
          return;
        }
      });
      sessionData = { ...sessionData, userRole };

      yield put({
        type: actions.setUserSessionData.type,
        payload: sessionData,
      });
      yield put({
        type: Slice.loginSuccess,
        payload: { access: response.data.auth.access, userRole },
      });
    } else {
      const { data, status } = response.response;
      if (status === StatusCode.BAD_REQUEST) {
        errorMess = Object.keys(data).map(k => {
          return data[k].join(', ');
        });
      }
      yield put({ type: Slice.loginFail.type, payload: { errorMess } });
    }
  } catch (error) {
    yield put({ type: Slice.loginFail.type, payload: { errorMess } });
  }
}

function* loginWatcher() {
  yield takeLatest(Slice.loginRequest, loginFlow);
}

export default loginWatcher;
