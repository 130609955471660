import React, { useEffect, useState } from 'react';
// UI
import { Helmet } from 'react-helmet-async';

import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Stack,
  Pagination,
} from '@mui/material';
import { AvatarMember } from 'app/components/Avatar';
import styled from 'styled-components/macro';

import { Wrapper, Content } from 'app/components/Wrapper';
import { Container } from 'app/components/Container';
import moment from 'moment';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';

// Redux & Sagas
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';
import Path from 'config/clientPath';
import FilterBar from './components/FilterBar';
import { useMemberSlice } from '../MemberPage/slice';
import { REQUESTING_TASK_STATUS } from 'constants/common';
import { selectMember } from '../MemberPage/slice/selectors';
import { RequestStatus } from 'constants/API';
import { useLocation } from 'react-router';
import { Loader } from 'app/components/Loader';
import { checkIsRequesting } from 'utils/helper';
import queryString from 'query-string';
import { useTimesheetSlice } from '../TimesheetPage/slice';
import { selectTimesheet } from '../TimesheetPage/slice/selectors';
import { STATUS_DISPLAY_TYPE, TaskStatus } from 'app/components/TaskStatus';
import { BillableTag } from 'app/components/BillableTag';
import { ProjectLink } from 'app/components/ProjectLink';

export function TimesheetApprovalPage() {
  const dispatch = useDispatch();
  const [currentStatus, setCurrentStatus] = useState(REQUESTING_TASK_STATUS);
  const [page, setPage] = useState(1);
  const [month, setMonth] = React.useState<moment.Moment | null>(null);

  const location = useLocation();
  const history = useHistory();

  const { setBreadcumbs } = useGlobalSlice().actions;

  const {
    approvalList,
    getTimesheetStatus,
    approveRequestStatus,
    getAllProjectsStatus,
    getAllMembersStatus,
  } = useSelector(selectTimesheet);
  const {
    actions: { getProjectRoleListRequest },
  } = useMemberSlice();
  const {
    actions: {
      resetTimesheetRequestStatus,
      getApprovalListRequest,
      getAllProjectsRequest,
      getAllMembersRequest,
    },
  } = useTimesheetSlice();

  const { project_role_metadata } = useSelector(selectMember);

  const isLoading = checkIsRequesting([
    getAllProjectsStatus,
    getAllMembersStatus,
    approveRequestStatus,
  ]);

  useEffect(() => {
    if (getTimesheetStatus === RequestStatus.SUCCESS) {
      const firstTask = _.head(approvalList?.data || []);
      setCurrentStatus(firstTask?.status || REQUESTING_TASK_STATUS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTimesheetStatus]);

  useEffect(() => {
    dispatch(getProjectRoleListRequest({}));
    dispatch(
      setBreadcumbs([
        { title: 'Timesheets', path: Path.TIMESHEET },
        { title: 'Approval' },
      ]),
    );
    const params = new URLSearchParams(window.location.search);
    setPage(_.toInteger(params.get('page') || 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      dispatch(resetTimesheetRequestStatus());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const reloadData = () => {
    const params = queryString.parse(window.location.search);
    const newCurrentDate = params?.month ? moment(params?.month) : moment();
    dispatch(
      getApprovalListRequest({
        page: Math.abs(_.toInteger(params?.page) || 1),
        month: newCurrentDate.clone().startOf('month').format('yyyy-MM'),
        member: params?.member || '',
        project: params?.project || '',
      }),
    );
  };

  useEffect(() => {
    if (month) {
      const monthString = month.format('yyyy-MM');
      dispatch(
        getAllProjectsRequest({
          month: monthString,
        }),
      );
      dispatch(
        getAllMembersRequest({
          month: monthString,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(month)]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const monthParam = params.get('month');
    const newMonth = monthParam ? moment(monthParam) : moment();
    setMonth(newMonth);
    reloadData();
    const newPage = Math.abs(_.toInteger(params.get('page')));
    setPage(newPage ? newPage : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number,
  ) => {
    let params = queryString.parse(window.location.search);
    const qs = queryString.stringify({ ...params, page: +newPage });
    history.push(`${Path.TIMESHEET_APPROVAL}?${qs}`);
    setPage(newPage);
  };

  return (
    <>
      <Helmet>
        <title>Timesheet Approval</title>
        <meta name="description" content="Palette" />
      </Helmet>
      <Container>
        <Wrapper>
          <Loader open={isLoading} />
          <Content spacing={2}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                Approval
              </Typography>
            </Box>

            <FilterBar currentStatus={currentStatus} />
            <WrapTable>
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 60 }}>No</TableCell>
                      <TableCell sx={{ minWidth: 140 }}>Project</TableCell>
                      <TableCell sx={{ minWidth: 160 }}>Member</TableCell>
                      <TableCell sx={{ minWidth: 90 }}>Role</TableCell>
                      <TableCell sx={{ minWidth: 70 }}>Rate</TableCell>
                      <TableCell sx={{ minWidth: 90 }}>Billable</TableCell>
                      <TableCell sx={{ minWidth: 80 }}>Efforts</TableCell>
                      <TableCell sx={{ minWidth: 90 }}>Detail</TableCell>
                      <TableCell sx={{ minWidth: 150 }}>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {approvalList?.data.map((task, key) => {
                      const currentMember = task?.member;
                      return (
                        <TableRow key={`approval_row_${key}`}>
                          <TableCell width={60}>
                            {(page - 1) * (approvalList?.page_size || 0) +
                              key +
                              1}
                          </TableCell>
                          <TableCell>
                            <ProjectLink
                              project_id={task?.project?.id}
                              project_name={task?.project?.name}
                            ></ProjectLink>
                          </TableCell>

                          <TableCell>
                            <Stack
                              direction="row"
                              spacing={'6px'}
                              alignItems="center"
                            >
                              <AvatarMember
                                avatar={`${currentMember?.avatar || ''}`}
                                title={`${currentMember?.full_name || ''}`}
                                sx={{ width: 24, height: 24, fontSize: 12 }}
                              ></AvatarMember>
                              <Box>{currentMember?.full_name}</Box>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {_.get(
                              _.find(project_role_metadata, {
                                id: _.toInteger(task.role),
                              }),
                              'name',
                            )}
                          </TableCell>
                          <TableCell>{task?.rate}</TableCell>
                          <TableCell>
                            <BillableTag billable={task?.type}></BillableTag>
                          </TableCell>
                          <TableCell>{task?.effort + 'h'}</TableCell>
                          <TableCell>
                            <Link
                              to={`${
                                Path.TIMESHEET_APPROVAL_REVIEW
                              }?month=${month?.format('YYYY-MM')}&project=${
                                task?.project?.id
                              }&member=${currentMember.id}`}
                              style={{
                                textDecoration: 'none',
                                color: '#0000ee',
                              }}
                            >
                              View Detail
                            </Link>
                          </TableCell>
                          <TableCell width={180}>
                            {task?.status && (
                              <TaskStatus
                                status={task?.status}
                                display_type={STATUS_DISPLAY_TYPE.ADMIN}
                              ></TaskStatus>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {_.isEmpty(approvalList?.data) && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={9}>No data</TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </WrapTable>
            <Stack spacing={2} alignItems={'flex-end'}>
              <Pagination
                page={page}
                color="primary"
                count={approvalList?.total_page || 0}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            </Stack>
          </Content>
        </Wrapper>
      </Container>
    </>
  );
}

const WrapTable = styled(Box)`
  & .MuiTableContainer-root {
    max-height: calc(100vh - 222px);
  }
`;
