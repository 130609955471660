import React from 'react';

// UI
import {
  Box,
  Stack,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import styled from 'styled-components/macro';
import WeekPicker from './WeekPicker';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { ReactComponent as ListViewModeIcon } from 'assets/icons/viewmode/sorting.svg';
import { ReactComponent as CalendarViewModeIcon } from 'assets/icons/viewmode/grid.svg';

// Redux & Sagas
import { selectTag } from 'app/pages/TagPage/slice/selectors';

// Library
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Path from 'config/clientPath';
import queryString from 'query-string';

interface IFilterBarProps {
  onChangeFilterDate: Function;
  onChangeToday: Function;
  setViewMode: Function;
  currentDate: moment.Moment;
  weekPickerRef: any;
  viewMode: string;
}

export function FilterBar(props: IFilterBarProps) {
  const {
    viewMode,
    onChangeFilterDate,
    currentDate,
    onChangeToday,
    weekPickerRef,
  } = props;
  const { handleSubmit, reset, control, watch } = useForm();

  const { tagList } = useSelector(selectTag);

  const searchForm = React.useRef<HTMLFormElement>();

  const history = useHistory();

  const handSearch = _formData => {
    let params = queryString.parse(window.location.search);
    const formData = { ...params, ..._formData };
    const qs = Object.keys(formData)
      .map(key => (formData[key] ? key + '=' + formData[key] : ''))
      .filter(o => o)
      .join('&');
    history.push(`${Path.TIMESHEET}?${qs}`);
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    reset({
      project: params.get('project') || '',
      tags: params.get('tags') || '',
      status: params.get('status') || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'tags') {
        requestSubmit();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const requestSubmit = () => {
    setTimeout(() => searchForm.current?.requestSubmit(), 10);
  };

  const changeViewMode = _viewMode => {
    let params = queryString.parse(window.location.search);
    if (!params?.viewMode || params?.viewMode !== _viewMode) {
      params = { ...params, viewMode: _viewMode };
    }
    const qs = queryString.stringify({ ...params });
    history.push(`${Path.TIMESHEET}?${qs}`);
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      component="form"
      method="GET"
      ref={searchForm}
      onSubmit={handleSubmit(handSearch)}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => onChangeToday()}
          >
            Today
          </Button>
          <WeekPicker
            viewMode={viewMode}
            value={currentDate}
            onChange={onChangeFilterDate}
            ref={weekPickerRef}
          />
        </Stack>
        <InputLabel>Tag: </InputLabel>
        <FormControl>
          <Controller
            control={control}
            name="tags"
            render={({ field }) => {
              const { onBlur, onChange, value } = field;
              return (
                <Select
                  fullWidth
                  displayEmpty
                  size="small"
                  variant="standard"
                  onBlur={onBlur}
                  onChange={onChange}
                  value={`${value}`}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  <MenuItem value="">All</MenuItem>
                  {tagList?.data.map(it => (
                    <MenuItem value={it.id}>{it.name}</MenuItem>
                  ))}
                </Select>
              );
            }}
          />
        </FormControl>
      </Stack>
      <Stack direction="row" spacing={2} alignItems="center">
        <InputLabel>View mode:</InputLabel>
        <Stack direction="row" spacing={0.5}>
          <ViewModeButton
            size="large"
            className={viewMode === 'list' ? 'active' : ''}
            onClick={() => {
              changeViewMode('list');
            }}
          >
            <ListViewModeIcon />
          </ViewModeButton>
          <ViewModeButton
            variant="text"
            size="large"
            className={viewMode === 'calendar' ? 'active' : ''}
            onClick={() => {
              changeViewMode('calendar');
            }}
          >
            <CalendarViewModeIcon />
          </ViewModeButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export default FilterBar;

const ViewModeButton = styled(Button)`
  padding: 6px 6px !important;
  min-width: auto;
  width: fit-content;
  min-width: auto !important;
  & path {
    fill: #000 !important;
  }
  background-color: none !important;
  &.active {
    background-color: #1976d2 !important;
    & path {
      fill: #fff !important;
    }
  }
`;
