import React from 'react';

// UI
import {
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from '@mui/material';

import { ReactComponent as SaveIcon } from 'assets/icons/button/save.svg';
import { ReactComponent as EditIcon } from 'assets/icons/button/edit.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/button/delete.svg';

// Redux & Sagas
import { useSelector } from 'react-redux';
import { selectTeam } from '../slice/selectors';
import { selectMember } from 'app/pages/MemberPage/slice/selectors';

// Library
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

interface IMemberRowProps {
  keyName: string;
  memberIndex: number;
}

export default function MemberRow(props: IMemberRowProps) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const { member_meta_data } = useSelector(selectMember);
  const { team_role_metadata } = useSelector(selectTeam);

  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

  const fieldName = `${props.keyName}[${props.memberIndex}]`;
  const handSaveMember = () => {
    if (_.isEmpty(errors)) {
      setIsEditMode(false);
    }
  };
  React.useEffect(() => {
    if (!getValues(`${fieldName}.id`)) {
      setIsEditMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handRemoveMember = () => {
    setValue(
      props.keyName,
      _.filter(
        getValues(props.keyName),
        (it, idx) => idx !== props.memberIndex,
      ),
    );
  };

  return (
    <TableRow>
      <TableCell>
        <FormLabel>{props.memberIndex + 1}</FormLabel>
      </TableCell>
      <TableCell>
        {isEditMode ? (
          <FormControl
            fullWidth
            error={'id' in (_.get(errors, fieldName) || {})}
          >
            <Controller
              control={control}
              name={`${fieldName}.id`}
              rules={{ required: true }}
              render={({ field }) => {
                const { onBlur, onChange, value } = field;
                return (
                  <Select
                    fullWidth
                    displayEmpty
                    size="small"
                    defaultValue=""
                    onBlur={onBlur}
                    onChange={onChange}
                    value={`${value}`}
                  >
                    {member_meta_data?.map(it => (
                      <MenuItem key={it.id} value={`${it.id}`}>
                        {it.full_name}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        ) : (
          <FormLabel>
            {_.get(
              _.find(member_meta_data, {
                id: _.toInteger(getValues([`${fieldName}.id`])),
              }),
              'full_name',
            )}
          </FormLabel>
        )}
      </TableCell>
      <TableCell>
        {isEditMode ? (
          <FormControl
            fullWidth
            error={'role_id' in (_.get(errors, fieldName) || {})}
          >
            <Controller
              control={control}
              name={`${fieldName}.role_id`}
              rules={{ required: true }}
              render={({ field }) => {
                const { onBlur, onChange, value } = field;
                return (
                  <Select
                    fullWidth
                    displayEmpty
                    size="small"
                    defaultValue=""
                    onBlur={onBlur}
                    onChange={onChange}
                    value={`${value}`}
                  >
                    {team_role_metadata?.map(it => (
                      <MenuItem key={it.name} value={`${it.id}`}>
                        {it.name}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }}
            />
          </FormControl>
        ) : (
          <FormLabel>
            {_.get(
              _.find(team_role_metadata, {
                id: _.toInteger(getValues([`${fieldName}.role_id`])),
              }),
              'name',
            )}
          </FormLabel>
        )}
      </TableCell>
      <TableCell>
        {isEditMode ? (
          <IconButton onClick={handSaveMember}>
            <SaveIcon />
          </IconButton>
        ) : (
          <IconButton onClick={() => setIsEditMode(true)}>
            <EditIcon />
          </IconButton>
        )}
        <IconButton onClick={handRemoveMember}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
