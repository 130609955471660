import React from 'react';

// UI
import {
  Box,
  Stack,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
  Autocomplete,
  IconButton,
} from '@mui/material';

import { Search as SearchIcon } from '@mui/icons-material';

// Redux & Sagas
import { useSelector } from 'react-redux';

// Library
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Path from 'config/clientPath';
import queryString from 'query-string';
import { ICommonDataSchema } from 'types/common';
import styled from 'styled-components/macro';
import _ from 'lodash';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import { selectTimesheet } from 'app/pages/TimesheetPage/slice/selectors';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export function FilterBar(props: { isCustomer: boolean }) {
  const { isCustomer } = props;
  const { handleSubmit, control, watch, setValue, getValues } = useForm();

  const defaultProject = { id: -1, name: 'All' };
  const [selectedProject, setSelectedProject] =
    React.useState<ICommonDataSchema | null>(defaultProject);

  const { allProjects, allMembers } = useSelector(selectTimesheet);
  const defaultMember = { id: -1, full_name: 'All' };
  const [selectedMember, setSelectedMember] =
    React.useState<any>(defaultMember);

  const searchForm = React.useRef<HTMLFormElement>();
  const history = useHistory();

  const handSearch = formData => {
    let newParams = {
      ...formData,
      project: selectedProject?.id || '',
      member: selectedMember?.id || '',
      month: formData?.month.format('YYYY-MM'),
    };
    // remove query param when has not value or value is -1 (all value)
    const qs = queryString.stringify(_.omitBy(newParams, v => !v || v === -1));
    history.push(
      `${
        isCustomer ? Path.CUSTOMER_TIMESHEET_REPORT : Path.TIMESHEET_OVERVIEW
      }?${qs}`,
    );
  };

  React.useEffect(() => {
    const params = queryString.parse(window.location.search);
    setValue('month', params?.month ? moment(params?.month) : moment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (params?.project) {
      const project = allProjects?.find(
        v => v.id === _.toNumber(params?.project),
      );
      if (project) {
        setSelectedProject(project);
        setValue('project', project.name);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProjects]);

  React.useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (params?.member) {
      const member = allMembers?.find(v => v.id === _.toNumber(params?.member));
      if (member) {
        setSelectedMember(member);
        setValue('member', member.full_name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMembers]);

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'project' || name === 'member' || name === 'month') {
        requestSubmit();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const requestSubmit = () => {
    setTimeout(() => searchForm.current?.requestSubmit(), 10);
  };

  const resetProjectAndMember = () => {
    setSelectedMember(defaultMember);
    setSelectedProject(defaultProject);
  };

  const changeMonth = diff_month => {
    setValue(
      'month',
      (getValues('month') || moment()).clone().add(diff_month, 'month'),
    );
    resetProjectAndMember();
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      component="form"
      method="GET"
      ref={searchForm}
      onSubmit={handleSubmit(handSearch)}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <InputLabel>Month: </InputLabel>
        <IconButton>
          <ChevronLeft
            onClick={() => {
              changeMonth(-1);
            }}
          />
        </IconButton>
        <FormControl margin="dense" sx={{ width: 115 }}>
          <Controller
            control={control}
            name="month"
            render={({ field: { onChange, value } }) => {
              return (
                <DatePicker
                  views={['month', 'year']}
                  value={value}
                  onChange={e => {
                    resetProjectAndMember();
                    onChange(e);
                  }}
                  inputFormat="YYYY/MM"
                  renderInput={params => (
                    <TextField {...params} fullWidth hiddenLabel size="small" />
                  )}
                />
              );
            }}
          />
        </FormControl>
        <IconButton>
          <ChevronRight
            onClick={() => {
              changeMonth(1);
            }}
          />
        </IconButton>

        <InputLabel>Project: </InputLabel>
        <FormControlCustom>
          <Controller
            control={control}
            name="project"
            render={({ field }) => {
              const { onChange } = field;
              return (
                <Autocomplete
                  value={selectedProject}
                  isOptionEqualToValue={(option, value) => {
                    return value?.id && value?.id === option?.id ? true : false;
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={`prj_${option.id}`}>
                      {option?.name}
                    </Box>
                  )}
                  options={[{ ...defaultProject }, ...allProjects]}
                  getOptionLabel={it => it?.name || ''}
                  onChange={(e, newValue) => {
                    setSelectedProject(newValue);
                    onChange(newValue?.name);
                  }}
                  renderInput={params => {
                    return (
                      <TextField {...params} size="small" variant="outlined" />
                    );
                  }}
                />
              );
            }}
          />
        </FormControlCustom>

        <InputLabel>Assignee: </InputLabel>
        <FormControlCustom className="member_form">
          <Controller
            control={control}
            name="member"
            render={({ field }) => {
              const { onChange } = field;

              return (
                <Autocomplete
                  value={selectedMember}
                  isOptionEqualToValue={(option, value) => {
                    return value?.id && value?.id === option?.id ? true : false;
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props} key={`member_${option.id}`}>
                      {option?.full_name}
                    </Box>
                  )}
                  options={[{ ...defaultMember }, ...allMembers]}
                  getOptionLabel={it => it?.full_name || ''}
                  onChange={(e, newValue) => {
                    setSelectedMember(newValue);
                    onChange(newValue?.full_name);
                  }}
                  renderInput={params => {
                    return (
                      <TextField {...params} size="small" variant="outlined" />
                    );
                  }}
                />
              );
            }}
          />
        </FormControlCustom>
      </Stack>

      {!isCustomer && (
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            type="search"
            placeholder="Search keyword"
            aria-label="Search"
            size="small"
            sx={{
              background: '#fff',
            }}
            onKeyUp={event => {
              if (event.key === 'Enter') {
                requestSubmit();
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            // {...register<keyof IMemberSchema>('full_name')}
          />
        </Stack>
      )}
    </Box>
  );
}

export default FilterBar;

const FormControlCustom = styled(FormControl)`
  width: 150px;
  & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon,
  .MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      padding-right: 50px !important;
    }
  }
  &.member_form {
    width: 190px;
    & .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon,
    .MuiAutocomplete-root {
      .MuiOutlinedInput-root {
        padding-right: 50px !important;
      }
    }
  }
`;
